import React,{Component} from 'react';
import Dashboard from './view/dashboard';
import UpdateBartUsers from './view/updateBartUsers'
import NotFoundPage from './view/notFoundPage';
import Home from './view/home';
import Admin from './view/admin';
import ResetPassword from './view/resetPassword';
import IdleTimer from 'react-idle-timer'
import {API_URL} from './view/constants'
import axios from 'axios'

import { BrowserRouter,HashRouter, Route, Switch } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalHeader,ModalBody, ModalFooter, } from 'reactstrap';
import notFoundPage from './view/notFoundPage';
import ReactGA from 'react-ga'

let modalBody = "";
let modalHeader = "";
let interval  = 0;

export const initReactGA = () =>{
  ReactGA.initialize('UA-140726016-2',{
    titleCase: false,});
}
export const loadPageView = ()=>{
  ReactGA.pageview(window.location.pathname);
}




class App extends Component {
  constructor(props) {
    super(props)
    this.state={
      modal:false,
      intervalId:0
    }
    this.idleTimer = null
    // this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.showTimeOut.bind(this);
    this.getSessionValue.bind(this);
    if (window.performance) {
      if (performance.navigation.type === 1) {
        let searchTerm = {};
        let seacrObject = JSON.stringify(searchTerm);
        localStorage.setItem('monitendSearchTerm', seacrObject);
        document.location.href="/";
      } 
    }
  }

  // _onAction(e) {
  //   console.log('user did something', e)
  // }

  _onActive(e) {
    if(this.idleTimer.getRemainingTime() === 0){
      if(localStorage.getItem('monitendUser') !== null  && localStorage.getItem('api_token') !== null  ) {
        if(this.state.modal === false){
          this.setState({
            modal: !this.state.modal,          
          })   
        }
      }
    }
    
  }

  _onIdle(e) {
    if(localStorage.getItem('monitendUser') !== null  && localStorage.getItem('api_token') !== null  ) {
      localStorage.removeItem('monitendUser');
      localStorage.removeItem('api_token');
      localStorage.removeItem('monitendSearchTerm');
      modalHeader = "Session Expired";
      modalBody = "Your session has expired, possibly due to inactivity. For your safety, you have been logged out and must sign in again to continue.";
      this.setState({
        modal: !this.state.modal,          
      })
      
      
    }
  }
  getSessionValue(sessionName){
    let value = ""
    let match = document.cookie.match(new RegExp('(^| )' + sessionName + '=([^;]+)'));
    if (match) {
      value = match[2];
    }
    return value;
  }
  componentDidMount(){
    initReactGA();
    loadPageView();
    this.checkLoginStatus();    
  }



  checkLoginStatus(){
    let userid = localStorage.getItem('monitendUser');
    let apiToken = localStorage.getItem('api_token');
    let MNSession = this.getSessionValue('MNSession');
    let loggedIn = true;
    if(userid !== null && apiToken !== null){
      
      //const url = `${API_URL}user?api_token=${apiToken}`;

      axios.post(`${API_URL}user`, {
        api_token: apiToken
      }).then(response => {
        if(!response.data.data){
          localStorage.removeItem('monitendUser');
          localStorage.removeItem('api_token');
          localStorage.removeItem('monitendSearchTerm');
          sessionStorage.removeItem('MNSession');
          //document.location.href="/";
          modalHeader = "You have been logged out!";
          modalBody = "The same account is not allowed to be used on different devices simultaneously.";
          this.setState({
            modal: !this.state.modal,          
          })
        }
      }).catch( (err) => {
        localStorage.removeItem('monitendUser');
        localStorage.removeItem('api_token');
        localStorage.removeItem('monitendSearchTerm');
        sessionStorage.removeItem('MNSession');
        modalHeader = "You have been logged out!";
        modalBody = "The same account is not allowed to be used on different devices simultaneously.";
        this.setState({
          modal: !this.state.modal,          
        })
    })
    
  }
}
  

  showTimeOut(){
    document.location.href="/";    
  }
 
  render() {
    
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          // onAction={this.onAction}
          // debounce={250}
          timeout={1000 * 60 * 30} />
          <div className="App">        
            <HashRouter basename="/">
                <Switch>
                  <Route exact path={"/"} component={Home}/>
                  <Route path={"/dashboard"} component={Dashboard}/>        
                  <Route path={"/updateBartUsers"} component={UpdateBartUsers}/>
                  <Route path="/:token" component={ResetPassword} />  
                  {/* <Route path={"/reset_password/:token"} render={(props) => <ResetPassword {...props} />}/> */}
                  <Route path="*" component={notFoundPage} />
                </Switch>
              </HashRouter>
          </div>
          <div style={{width:'50vw'}}>
                <Modal size="lg" isOpen={this.state.modal}>
                  <ModalHeader  charCode="x" className="modal_title">{modalHeader}</ModalHeader>
                  <ModalBody className="modalBody">
                   {modalBody}
                  </ModalBody>
                  <ModalFooter> 
                    <button onClick={this.showTimeOut} type="button" className="form-button">Ok</button>                 
                  </ModalFooter>
                </Modal>
          </div>
      </div>
      
    );
  }
}

export default App;
