import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import {API_URL} from '../constants'
import axios from 'axios'
import _ from "lodash";
import debounce from "debounce-promise";


let products = [];



export default class ProductsInput extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      inputValue: ''
    }
    this.handleProductChange.bind(this)
    this.promiseOptions.bind(this)
    this.loadProd.bind(this)

    const wait = 500; // milliseconds
    const loadOptions = (inputValue, type) => this.getAsyncOptions(inputValue, type);
    this.debouncedLoadOptions = debounce(loadOptions, wait, {
      leading: true
    });
  }

  getAsyncOptions(inputValue, type) {
    console.log(type)
    let url = `${API_URL}product?name=${inputValue}`;
    if(inputValue.length){
    axios.post(url).then(response => {
      products = response.data.data
      
    })
    return new Promise((resolve, reject) => {
      const filtered = _.filter(products, o =>
        _.startsWith(_.toLower(o.label), _.toLower(inputValue))
      );
      resolve(filtered.slice(0, 10));
    });

    
  }
  }
 
  promiseOptions=(inputValue)=>{
    console.log(inputValue)
  }
  loadProd=()=>{

  }
  handleProductChange=(selectedProduct)=>{
    this.setState({ selectedProduct });
  }
  render() {
    return (
      
        <AsyncSelect id="products"
        isMulti
        cacheOptions
        defaultOptions
        //loadOptions={this.getAsyncOptions}
        onChange={this.handleProductChange}
        loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "product")}
      />
      
      
    );
  }
}
