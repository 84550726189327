import React, { Component } from 'react';
import { Row, Col,Input, Form, Label, InputGroupAddon, InputGroup  } from 'reactstrap';
import molecule from '../images/molecule.png';
import atcIcon from '../images/atc.png';
import nan from '../images/nan.png';
import { faSearch, faArrowRight, faArrowLeft, faWeight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faPills, faBoxes, faCoins, faAngleDoubleDown, faVial,faTablets,faBuilding, faSearchLocation, faPrescriptionBottle, faCalendarDay} from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import ProductsInput from './autoCompleteInputs/productsInput'
import SubstanceInput from './autoCompleteInputs/substanceInput'
import StrengthInput from './autoCompleteInputs/strengthInput'
import ProductContracts from './productContracts'
import Products from './products'
import SalesVolume from './salesVolume'
import SalesValue from './salesValue'
import HelpModal from './helpModal'
import Iframe from 'react-iframe'
import ReactGA from 'react-ga'
import Tooltip from 'react-tooltip-lite';



import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
  } from 'reactstrap';

import logo from '../images/logo.png';
import statImage from '../images/stats.png';

import { faQuestionCircle, faFileExcel, faSignOutAlt, faBookOpen,faCog,faDownload,faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import SizeInput from './autoCompleteInputs/sizeInput'
import {API_URL, DOWNLOAD_URL} from './constants'
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { Modal, ModalHeader,ModalBody, ModalFooter,Badge} from 'reactstrap';
import _ from "lodash";
import debounce from "debounce-promise";


 
import "react-datepicker/dist/react-datepicker.css";
import { Transition } from 'react-transition-group';

const question = <FontAwesomeIcon icon={ faQuestionCircle} />
const infoIcon = <FontAwesomeIcon icon={ faInfoCircle} />
const signout = <FontAwesomeIcon icon={ faSignOutAlt} />
const bookOpen = <FontAwesomeIcon icon={faBookOpen}/>
const fileContract = <FontAwesomeIcon icon={ faFileContract} />
const pills = <FontAwesomeIcon icon={ faPills} />
const boxes = <FontAwesomeIcon icon={ faBoxes} />
const coins = <FontAwesomeIcon icon={ faCoins} />
const faAngledown = <FontAwesomeIcon icon={ faAngleDoubleDown} />
const faVialIcon = <FontAwesomeIcon icon={faVial}/>
const tablets = <FontAwesomeIcon icon={faTablets}/>
const forms = <FontAwesomeIcon icon={faPills}/>
const companyIcon = <FontAwesomeIcon icon={faBuilding}/>
const area = <FontAwesomeIcon icon={faSearchLocation}/>
const strengthIcon = <FontAwesomeIcon icon={faPrescriptionBottle}/>
const calendarDay = <FontAwesomeIcon icon={faCalendarDay}/>
const scale = <FontAwesomeIcon icon={faWeight}/>
const download = <FontAwesomeIcon icon = {faDownload}/>
const spinner = <FontAwesomeIcon icon={faCog} spin  />
let   dowloadURL = {};

const search = <FontAwesomeIcon icon={ faSearch} />
const LeftArraow = <FontAwesomeIcon icon={ faArrowLeft} />
let modalHeader;
let modalBody;
let numberofRows;
let screenHeight = 0;
  let termUpdate = false;
  let products=[];
  let substance = [];
  let vnr = [
    
  ];

  let atc =[
   
  ];
  let company = [
    
  ];
  let form = [

  ];
  let strength =[];
  
  var prodData = [];
  let size = [];
  let region = [];
  let contractInfo = [];
  let updateInfo = [];
  let tableHeightPx;
  let token = "";
  

let secondarySearch = 0;
let expiringWithinThree = 0;
let expiringWithinTwelve = 0;
let expired = 0;
let upcoming = 0;
let onlyValid = 0;
let clickedSearch = 0;
let searchTermUpdated = false;
let MNSession = "";


let exportActive;

let tutorialReady = 0;
const downLoadStyle = {
  inactive:{
      color:"#dedede",
      padding:"7px"
  },
  spinning:{
    padding:"7px",
    color:"#737576"
  }
  //...other styles...
};

export const initReactGA = () =>{
  ReactGA.initialize('UA-140726016-2',{
    titleCase: false,});
}

export const loadPageView = ()=>{
  ReactGA.pageview(window.location.pathname);
}


class Dashboard extends Component {
   constructor(props){
       super(props);
       token = localStorage.getItem('api_token');
       MNSession = this.getSessionValue('MNSession');
       this.state = {
            loggedInStatus : (this.props.location.state) ? this.props.location.state.loggedInStatus :'NOT_LOGGED_IN',
            user : (this.props.location.state) ? this.props.location.state.user:{},
            tutorialActive :0,
            seenOnce: 1,
            active: true,
            infoDivactive: true,
            modal:false,
            modal2:false,
            selectedProduct: '',
            selectedSubstance : '',
            selectedVnr : '',
            selectedAtc:'',
            selectedForm:'',
            selectedCompany:'',
            selectedStrength: '',
            selectedSize:'',
            selectedToDate:"",
            selectedFromDate:"",
            selectedRegion:"",
            searchTerm:{},
            tabIndex:0,
            clickedSearch :0,
            clickedDownload: 0,           
            onlyValid:1,
            regionWise:1,
            api_token:token,
            rightPanClicked:false,
            contractInfo : {},
            secondarySearch : false,
            secondarySearchClicked : false,
            updateInfo:"",
            automaticUpdates:"",
            searchTermUpdated:false,
            expiringWithinThree:0,
            expiringWithinTwelve:0,
            expired:0,
            upcoming:0,
            numberofRows:27,
            session : MNSession,
            inputValue: '',
            tableheight:'',
            noticeInfo:"",
            tootlTips:[],
            arrayRegion :[],
            walkThrough:"",
            tutorialStarted:0,
            
            formAlert:0,
            showHelp:0,

            stepsEnabled: true,
            currentStep: 0,
            autoTutorial:0,
            urlComponent:"",
            showSpinner:false,
            allCodeAccess:localStorage.getItem('allCodeAccess'),
          


          
            steps:[
              {
                title: 'Welcome',
                desc: 'Dear User, This tutorial will take you through the functions of Monitend. Please follow the instructions in each step. Click ‘Next’ to proceed.',
                selector: '#walkthrough_salesval',
                fixPadding: false,
                topMargin: '0px',
                marginLeft: '',
                top:"30%",
                topPadding:'0',
                curatinPaddingLeft:''             
                
                
              },
            
              {
                title: 'Search Form',
                desc: 'Here, on the left hand side of the screen, you will find the search form. You can filter a search by adding one, or multiple, inputs for the parameter/-s you desire to filter on.',
                selector: '.search_form',
                fixPadding: false,
                topMargin: '45px',
                marginLeft: '100%',
                width:'300px',
                left: '0px',
                top:"0%",
                topPadding:'0%',
                disableSelector: '#form_buttons',
                curatinPaddingLeft:'-10px'
            },

            {
              title: 'Search on Desired Substance',
              desc: 'To search for a substance; start typing the name of a substance to get a list of suggestions. Choose one and click ‘Next’.',
              selector: '#guided_tour1',
              fixPadding: false,
              topMargin: '0px',
              marginLeft: '',
              left: '0px',
              top:"10%",
              topPadding:'30%',
              curatinPaddingLeft:'-10px'
              
              
            },
            {
                title: 'Search on Desired Substance',
                desc: 'Click on the ‘Search’-button to proceed. Please Note: The search form can be found by clicking the bar (or the magnifying glass) on the left hand side of the screen after searching.',
                selector: '#form_buttons',
                fixPadding: false,
                topMargin: '0px',
                marginLeft: '',
                left: '0px',
                top:"45%",
                bottomPadding:'17%',
                curatinPaddingLeft:'-10px'
              
            },
            {
              title: 'Products with Contracts',
              desc: 'Valid contracts are now displayed in this first tab ‘Products with Contracts’. On the right hand side of the column ‘Size’ the table is scrollable to the right or left to choose which columns are visible at the moment. Please Note: To see historical contracts untick ‘Valid contracts only’ in the search form.',
              selector: '.tabs_responsive',
              fixPadding: false,
              topMargin: '50px',
              marginLeft: '',
              width:'700px',
              top: '50px',
              left: '-6px',
              promptBackground: '#b4eded'
            
            },
            {
              title: 'Sorting',
              desc: 'All columns that have white headlines are sortable by simply clicking the headline. The order, ascending or descending, is switched by clicking the headline another time. Clicking a third time will reset the sorting.',
              selector: '.tabs_responsive',
              fixPadding: false,
              topMargin: '50px',
              marginLeft: '',
              width:'700px',
              top: '50px',
              left: '-6px',
              promptBackground: '#b4eded'
            
            },
            {
              title: 'Alterations and Graphs',
              desc: 'To see if any contract alterations have been made, click on the row and these will appear. Please Note: If no alterations have been made the contract price will be gray. Graphs comparing prices between regions are shown by clicking the prices for ‘Lowest Price NAN’ or ‘Lowest Price Product’.',
              selector: '.tabs_responsive',
              fixPadding: false,
              topMargin: '50px',
              marginLeft:'150px',
              width:'700px',
              top: '50px',
              left: '-6px',
              promptBackground: '#b4eded'
            
            },
           
            {
                title: 'Contract information and update',
                desc: 'Here, at the right hand side of the screen, you will find information about the contracts within the current search at the upper section. Below you can see when the last update was run for each region. Click the arrow to minimize and ‘i’ to maximize it again.', 
                selector: '#walkthrough_salesval',
                fixPadding: false,
                topMargin: '50px',
                width:'300px',
                top:"30%",
                marginLeft: '80%',
                topPadding:'10px',
                curatinPaddingLeft:'',
                promptBackground: '#b4eded'
               
              
              },
              {
                title: 'Products',
                desc: 'This tab lists all products that applies to the set conditions within the current search. ',
                selector: '.tabs_responsive',
                fixPadding: false,
                topMargin: '50px',
                width:'700px',
                top: '50px',
                left: '-6px',
                promptBackground: '#b4eded',
                Transition:'2s'
              
              },
              
              {
                title: 'Sales',
                desc: 'In the tabs ‘Sales - Units’ and ‘Sales - Value’ you will see region-wise sales data in units or value respectively. Products within contracts are marked with a darker color. Hover on a row that corresponds to a contract to see a tooltip with some contract info.',
                selector: '.tabs_responsive',
                fixPadding: false,
                topMargin: '50px',
                width:'700px',
                top: '50px',
                left: '-6px',
                promptBackground: '#b4eded'
              
              },
          
              {
                title: 'National sales data',
                desc: 'To see sales data on national level, simply untick ‘Region-wise sales data’ at the bottom of the search field, and perform a new search. ',
                selector: '.tabs_responsive',               
                fixPadding: false,
                topMargin: '50px',
                width:'700px',
                top: '50px',
                left: '-6px',
                left: '0px',
                promptBackground: '#b4eded'              
                
                
            },
            {
              title: 'National sales data',
              desc: 'If you uticked ‘Region-wise sales data’ in previous step, you will have sales on national level visible.',
              selector: '.tabs_responsive',               
              fixPadding: false,
              topMargin: '50px',
              width:'700px',
              top: '50px',
              left: '-6px',
              left: '0px',
              promptBackground: '#b4eded'              
              
              
          },

            {
              title: 'Export function',
              desc: 'Search completed! All data within a search can be exported into Excel by clicking this button.',
              selector: '#iFrame',
              fixPadding: false,
              topMargin: '110px',
              left: '0px',
              top:"-15%",
              topPadding:'10px',
              width:'300px',
              marginLeft:'-100px',
              curatinPaddingLeft:'-10px'
          },
          

          {
                title: 'Tutorial finished',
                desc:  'Thank you for fulfilling this tutorial! To see this tutorial again or a user manual, go to ‘Help’ which you will find next to Export-button.',
                selector: '#walkthrough_salesval',
                fixPadding: false,
                topMargin: '0px',                
                top:"30%",
                topPadding:'10px',
                curatinPaddingLeft:'' 
          }
            
            
        
            ],
            

        };

        const wait = 500; // milliseconds
        const loadOptions = (inputValue, type) => this.getAsyncOptions(inputValue, type);
        this.debouncedLoadOptions = debounce(loadOptions, wait, {
          leading: true
        });
        
        this.handleProductChange.bind(this);
        this.handleRegionChange.bind(this);
        this.toggleClass.bind(this);
        this.toggleInfoDivClass.bind(this)
        this.handleSubstanceChange.bind(this);
        this.handleVnrChange.bind(this);
        this.handleAtcChange.bind(this);
        this.handleCompanyChange.bind(this);
        this.handleStrengthChange.bind(this);
        this.handleSizeChange.bind(this);
        this.handleOnToDateSelect.bind(this);
        this.handleOnFromDateSelect.bind(this);
        this.handleFormChange.bind(this)
        this.resetForm.bind(this);
        this.searchItems.bind(this);
        this.formatDate.bind(this);
        
        
        
        this.validOnly.bind(this);
        this.validOnlyContracts.bind(this);
        this.expiringWithin3.bind(this);
        this.expiringWithin12.bind(this);
        this.expiredContracts.bind(this);
        this.upcomingConntract.bind(this);
        this.totalContractContracted.bind(this);
        this.checkLoginStatus.bind(this);
        this.getSessionValue.bind(this);
        this.showTimeOut.bind(this);
        this.toogleLeftPannel.bind(this);
        this.hoverEffectArraow.bind(this);
        this.mouseOutLeftPannel.bind(this);
        this.startTutorial.bind(this);
        this.handelLogout.bind(this);
        this.toogleModal.bind(this);
        this.toogleModal2.bind(this);
        this.showHelpModal.bind(this)
        this.getAtcCodeFromSubstance.bind(this);
        this.getElementByStage.bind(this);
        this.prev.bind(this);
        this.next.bind(this);
        this.deleteCurrentPrompt.bind(this);
        
        this.resizeSecondTable.bind(this);
        
        this.startDownload.bind(this);
        this.loadNotice.bind(this);
        this.loadTooltips.bind(this);
        

        if (window.performance) {
          if (performance.navigation.type === 1) {
            let searchTerm = {};
            let seacrObject = JSON.stringify(searchTerm);
            localStorage.setItem('monitendSearchTerm', seacrObject);
            document.location.href="/";
          } 
        }
        
   }

   
   startDownload=()=>{
     
     if (localStorage.getItem("monitendSearchTerm") !== null) {
      this.setState({
        showSpinner:true
      })
      let searchTerm = localStorage.getItem('monitendSearchTerm');
      
      let searchT = JSON.parse(searchTerm);
      
      searchT.download = 1;
      let searchTString = JSON.stringify(searchT);
      axios.post(`${API_URL}fileDownload`, {
          seacrhObj: searchTString
      }          
      ).then(response=>{ 
        if(response.data.data.success){
          let a = document.createElement('a');
          a.href = `${API_URL}/export/${response.data.data.link}`;
          //a.href = `${API_URL}storage/${response.data.data.link}`;
          a.click();
          this.setState({
            showSpinner:false           
          })
          
          //google analytics
          ReactGA.event({
            category: 'XLSX_Export',
            action: 'export',
            label: 'Export results'
          });
          
        }
        
      }).catch( (err) => {
        this.setState({
          showSpinner:false          
        })
      })
    }
     
   }
   toogleModal=()=>{
    this.setState({
      modal:!this.state.modal,
      
      
    })
  }
  toogleModal2=()=>{
    this.setState({
      modal2:!this.state.modal2,
      
    })
  }

  showHelpModal=(e)=>{
    this.setState({
      showHelp:1

    })
    this.toogleModal();

  }
   
   handelLogout = (e) =>{   
      localStorage.removeItem('monitendUser');
      localStorage.removeItem('api_token');
      localStorage.removeItem('allCodeAccess'); 
      localStorage.removeItem('monitendSearchTerm');
      localStorage.removeItem('tutorial_active');
      localStorage.removeItem('seen_once');
      sessionStorage.removeItem('MNSession');
      this.setState({
        user:{},
        loggedInStatus : 'NOT_LOGGED_IN'
      })
      document.location.href="/";
  }
  deleteCurrentPrompt=(currentStep)=>{
    
    let htmlElem = document.querySelectorAll(this.state.steps[currentStep].selector)[0]
    let prompt = document.querySelectorAll('.tutorial-prompt')
    
    if(prompt.length>0){
      prompt.forEach(element => {
        element.parentElement.removeChild(element);    
        let curtainElem = document.querySelectorAll('.tutorial-curtain')[0]
        if(curtainElem){
          curtainElem.parentElement.removeChild(curtainElem)
        }
        let tutorialElem = document.querySelectorAll('.tutorial-element')[0]
        if (tutorialElem){
        tutorialElem.classList.remove('tutorial-element');
        }
        
      });
    }
  }
  getElementByStage=(currentStep)=> {
    
   if(currentStep<this.state.steps.length){
      if(currentStep ===1){
        document.getElementById("submit_form").disabled  = true;
        document.getElementById("reset_form").disabled  = true;
      }
      let formSubmitEnabled = [3,10]
      if( formSubmitEnabled.includes(currentStep) ){
        document.getElementById("submit_form").disabled  = false;
      }
      
      
      let formSubmitDisable = [4,5,6,7,8,9]
      if( formSubmitDisable.includes(currentStep) ){
        document.getElementById("submit_form").disabled  = true;
      }
     
      let htmlElem = document.querySelectorAll(this.state.steps[currentStep].selector)[0]
      if(htmlElem){
        let parent = htmlElem.parentElement;
        parent.style.position = 'relative';
      
      
     
        htmlElem.classList.add('tutorial-element');
      
      
      
        if(currentStep === 1){
          var form = document.getElementById("search_form");
          var elements = form.elements;
          for (var i = 0, len = elements.length; i < len; ++i) {
              elements[i].readOnly = true;
          }
        }else{
          var search_form = document.getElementById("search_form");
          var element = search_form.elements;
          for (var j = 0, l = element.length; j < l; ++j) {
            element[j].readOnly = false;
          }
        }
      
        let spinnerItem= document.querySelectorAll('.spinning_pill')[0];
        if(spinnerItem){
          spinnerItem.setAttribute("style", "margin-top:30vh");
        }
        let curtain = document.createElement('div');
        curtain.className = 'tutorial-curtain';
      
        if(currentStep === 0 ){//||currentStep === 5
          curtain.setAttribute("style", "left:-50%;width:200%;");
        }
        
        if(currentStep === 7){
          curtain.setAttribute("style", "width: 200vw;height:150vh;margin-left:-50vw;");
        }
        if(currentStep === 13){
          curtain.setAttribute("style", "width: 150vw;height:150vh;margin-left:-50vw;");
        }
      
        if(currentStep === 4 ){
          let tutorialElement = document.querySelectorAll('.tutorial-element')[0]
          tutorialElement.setAttribute("style", "z-index:0; bankground:#fff;")
          
        }

        if(this.state.steps[currentStep].curatinPaddingLeft){
          curtain.setAttribute("style", "left:"+this.state.steps[currentStep].curatinPaddingLeft+";");
        }
        let nonCurtainSteps = [4,5,6,7,8,9,11]
        if( !nonCurtainSteps.includes(currentStep) ){
          parent.appendChild(curtain);
        }
        if(currentStep === 12){
          curtain.setAttribute("style", "width: 150vw;margin-left: -100vw;height: 150vh;background: #000;");
        }

        
       

        let prompt = document.createElement('div');
        prompt.className = 'tutorial-prompt';
        prompt.id = "tutorial_prompt_id"
        if(!this.state.steps[currentStep].fixPadding){
          
          prompt.setAttribute("style", "margin-top:"+this.state.steps[currentStep].topMargin+";padding-top:"+this.state.steps[currentStep].topPadding+";padding-bottom:"+this.state.steps[currentStep].bottomPadding+";top:"+this.state.steps[currentStep].top+";margin-left:"+this.state.steps[currentStep].marginLeft+";margin-right:"+this.state.steps[currentStep].marginRight+";width:"+this.state.steps[currentStep].width+";background:"+this.state.steps[currentStep].promptBackground+";transition:"+this.state.steps[currentStep].Transition+";")
        }
      
    
      
        parent.appendChild(prompt);
        
        
        if( [0,14].includes(currentStep) ){
          prompt.setAttribute("style", "margin-top:30vh;");
          htmlElem.setAttribute("style", "z-index:2000");
        }

        if(currentStep === 13){
          let rightPannel = document.getElementById("rightSideInfo")
          if(rightPannel){
            if(rightPannel.classList.contains('update_info_toggled')){
              let currentState = this.state.infoDivactive;
              this.setState({ infoDivactive: !currentState, rightPanClicked:!this.state.rightPanClicked});
            }
            
            rightPannel.setAttribute("style","display:none;");
          }
      
          let searchPannel = document.getElementById("search_pannel")
          if(searchPannel){
            if(searchPannel.classList.contains('grid_item1_toggled')){
              searchPannel.classList.remove("grid_item1_toggled");
              searchPannel.classList.add("grid_item1");
              this.toggleClass();
            }
          }
        }
        
      
      
        let inner = document.createElement('div');
        inner.className = 'tutorial-inner';
        
        let elementObject = this.state.steps[this.state.currentStep]
        
        if (elementObject.title) {
          let title = document.createElement('span');
          title.className = 'tutorial-title';
          
          title.textContent = elementObject.title;
          inner.appendChild(title);
        }
        
        let desc = document.createElement('p');
        desc.className = 'tutorial-desc';
        desc.innerHTML = elementObject.desc;
        inner.appendChild(desc);
        prompt.appendChild(inner);

      
        
        let next = document.createElement('button');
        next.className = 'tutorial-next tutorial-btn';
        next.id = 'next_done_btn';
        
        next.addEventListener('click', this.next);
        next.textContent = this.state.currentStep < this.state.steps.length - 1 ? 'next' : 'done';
        
        if( !formSubmitEnabled.includes(currentStep) ){
          inner.appendChild(next);
        }
      
        


        let footer = document.createElement('div');
        footer.className = 'tutorial-footer';
        inner.appendChild(footer);

        let steps = document.createElement('span');
        steps.className = 'tutorial-steps';
        steps.textContent = `step ${this.state.currentStep + 1} of ${this.state.steps.length}`;
        footer.appendChild(steps);    

      }

   }else{
    document.location.href="/";
    
  } 
   
  
  }

  prev=()=>{

  }
  next=(e)=>{
    
    e.preventDefault();
    let goToNext = true;
    
    if(this.state.currentStep === 2 && this.state.selectedSubstance === ""){      
      goToNext = false;
    }
    if(this.state.currentStep === 3 ){
      goToNext = false;
    }
    if(this.state.currentStep === 10){
      goToNext = false;
    }

    if(goToNext){
     
      if(this.state.currentStep === 7){
        let rightSideElem = document.getElementById("rightSideInfo")
        rightSideElem.removeAttribute("style");
        
      }
      
      
      this.deleteCurrentPrompt(this.state.currentStep);
      this.setState({
        currentStep:this.state.currentStep+1
      })
    }
    
  }

 

  startTutorial=()=>{
    if(!tutorialReady){
      let rightPannel = document.getElementById("rightSideInfo")
      if(rightPannel){
        if(rightPannel.classList.contains('update_info_toggled')){
          let currentState = this.state.infoDivactive;
          this.setState({ infoDivactive: !currentState, rightPanClicked:!this.state.rightPanClicked});
        }
      }

      let searchPannel = document.getElementById("search_pannel")
      if(searchPannel){
        if(searchPannel.classList.contains('grid_item1_toggled')){
          searchPannel.classList.remove("grid_item1_toggled");
          searchPannel.classList.add("grid_item1");
          this.toggleClass();
        }
      }
    }
    

    // element.classList.add("zindex_3");
    if(!this.state.tutorialStarted){
      if(this.state.steps.length>0){
        this.setState({tutorialStarted:1})      
        axios.post(`${API_URL}opened_tutorial`,{
          api_token:token
        })
      }

    }
   }
  
   mouseOutLeftPannel=(e)=>{
    var element = document.getElementById("search_button_effect");
    if(element.classList.contains('search-button')){
      if(element.classList.contains('hover_effect')){
        element.classList.remove("hover_effect");
      }
    }else{
      if(element.classList.contains('toggled_button_hover')){
        element.classList.remove("toggled_button_hover");
      }
    }
    e.preventDefault();
   }
   hoverEffectArraow=(e)=>{
    var element = document.getElementById(e.target.id);
    if(e.target.id === 'search_button' ||( (e.target.id === 'search_pannel')&& element.classList.contains('grid_item1_toggled'))){
      
      var element = document.getElementById("search_button_effect");
      if(element.classList.contains('search-button')){
        if(element.classList.contains('hover_effect')){
          element.classList.remove("hover_effect");
        }else{
          element.classList.add("hover_effect");
        }
      }else{
        if(element.classList.contains('toggled_button_hover')){
          element.classList.remove("toggled_button_hover");
        }else{
          element.classList.add("toggled_button_hover");
        }
      }
      
    }
    
   }
   toogleLeftPannel=(e)=>{
    var element = document.getElementById(e.target.id);
     if(( (e.target.id === 'search_pannel')&& element.classList.contains('grid_item1_toggled'))){
      this.toggleClass();
     }
     
    var elementExists = document.getElementById("parent_div_1");
    
    if(elementExists !== null){

      let div1 = document.getElementById('parent_div_1');
        if(div1){
          
          document.getElementById('parent_div_2').style.maxWidth = "";
          
        }
      
    }
   }
   getAsyncOptions(inputValue, type) {
    if(type === "product"){
      let url = `${API_URL}product?name=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          products = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(products, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 10));
        });
      }
    }

    if(type === "substance"){
      let url = `${API_URL}substance?substance=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          substance = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(substance, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 10));
        });
      }
    }

    if(type === "vnr"){
      let url = `${API_URL}nan?nan=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          vnr = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(vnr, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 10));
        });
      }
    }
    if(type === "atc"){  
      let url = `${API_URL}atc?atcCode=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          atc = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(atc, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 10));
        });
      }
    } 
    if(type === "company"){  
      let url = `${API_URL}company?term=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          company = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(company, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 10));
        });
      }
    }

    if(type === "form"){  
      let url = `${API_URL}form?form=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          form = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(form, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 20));
        });
      }
    }
    if(type === "region"){  
      let url = `${API_URL}regions?region=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          region = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(region, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 10));
        });
      }
    }
    if(type === "strength"){  
      let url = `${API_URL}strength?strength=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          strength = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(strength, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 20));
        });
      }
    }

    if(type === "size"){  
      let url = `${API_URL}size?size=${inputValue}`;
      if(inputValue.length){
        axios.post(url).then(response => {
          size = response.data.data
          
        })
        return new Promise((resolve, reject) => {
          const filtered = _.filter(size, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          resolve(filtered.slice(0, 20));
        });
      }
    }
  }

   showTimeOut(){
    localStorage.removeItem('monitendUser');
    localStorage.removeItem('api_token');
    localStorage.removeItem('monitendSearchTerm');
    document.location.href="/";    
  }
   
   validOnlyContracts=(e)=>{
    
    var validOnlyChk = document.getElementById("valid_contracts_only_chk");
    if (!validOnlyChk.checked) {
      validOnlyChk.checked = true;
    } 


    let searchObj = this.state.searchTerm;

    searchObj.onlyValid = 1;
    searchObj.clickedSearch=1;
    searchObj.secondarySearch = true;
    searchObj.searchTermUpdated= true;
    searchObj.expiringWithinThree=0;
    searchObj.expiringWithinTwelve=0;
    searchObj.expired=0;
    searchObj.upcoming=0;
    searchObj.currentPage=1;
    
    this.setState({
      searchTerm : searchObj,
      secondarySearch : true,
      clickedSearch:1,
      onlyValid : 1,
      searchTermUpdated: true,
      expiringWithinThree:0,
      expiringWithinTwelve:0,
      expired:0,
      upcoming:0,
    })
    let seacrObject = JSON.stringify(searchObj);
    localStorage.setItem('monitendSearchTerm', seacrObject);
    
    //this.searchItems()
    e.preventDefault();
   }

   expiringWithin3=(e)=>{
   
    var validOnlyChk = document.getElementById("valid_contracts_only_chk");
    if (!validOnlyChk.checked) {
      validOnlyChk.checked = true;
    } 

    let searchObj = this.state.searchTerm;
    searchObj.expiringWithinThree = 1;
    searchObj.secondarySearch = true
    searchObj.onlyValid = 0;
    searchObj.clickedSearch=1;
    searchObj.searchTermUpdated= true;
    searchObj.expiringWithinTwelve=0;
    searchObj.expired=0;
    searchObj.upcoming=0;
    searchObj.currentPage=1;

    let seacrObject = JSON.stringify(searchObj);
    localStorage.setItem('monitendSearchTerm', seacrObject);
    this.setState({
      searchTerm : searchObj,
      secondarySearch : true,
      clickedSearch:1,
      onlyValid : 1,
      searchTermUpdated: true,
      expiringWithinThree:1,
      expiringWithinTwelve:0,
      expired:0,
      upcoming:0,
    })
    e.preventDefault();
   }

   expiringWithin12=(e)=>{

    var validOnlyChk = document.getElementById("valid_contracts_only_chk");
    if (!validOnlyChk.checked) {
      validOnlyChk.checked = true;
    } 
    
    let searchObj = this.state.searchTerm;
    searchObj.expiringWithinThree = 0;
    searchObj.secondarySearch = true
    searchObj.onlyValid = 0;
    searchObj.clickedSearch=1;
    searchObj.searchTermUpdated= true;
    searchObj.expiringWithinTwelve=1;
    searchObj.expired=0;
    searchObj.upcoming=0;
    searchObj.currentPage=1;
    let seacrObject = JSON.stringify(searchObj);
    localStorage.setItem('monitendSearchTerm', seacrObject);
    this.setState({
      searchTerm : searchObj,
      secondarySearch : true,
      clickedSearch:1,
      onlyValid : 1,
      searchTermUpdated: true,
      expiringWithinThree:0,
      expiringWithinTwelve:1,
      expired:0,
      upcoming:0,
    })
    e.preventDefault()
   }

   expiredContracts=(e)=>{
   
    var validOnlyChk = document.getElementById("valid_contracts_only_chk");
    if (validOnlyChk.checked) {
      validOnlyChk.checked = false;
    } 
    let searchObj = this.state.searchTerm;
    searchObj.expiringWithinThree = 0;
    searchObj.secondarySearch = true
    searchObj.onlyValid = 0;
    searchObj.clickedSearch=1;
    searchObj.searchTermUpdated= true;
    searchObj.expiringWithinTwelve=0;
    searchObj.expired=1;
    searchObj.upcoming=0;
    searchObj.currentPage=1;
    let seacrObject = JSON.stringify(searchObj);
    localStorage.setItem('monitendSearchTerm', seacrObject);
    this.setState({
      searchTerm : searchObj,
      secondarySearch : true,
      clickedSearch:1,
      onlyValid : 0,
      searchTermUpdated: true,
      expiringWithinThree:0,
      expiringWithinTwelve:0,
      expired:1,
      upcoming:0,
    })
    e.preventDefault()
   }

   upcomingConntract=(e)=>{
    
    var validOnlyChk = document.getElementById("valid_contracts_only_chk");
    if (validOnlyChk.checked) {
      validOnlyChk.checked = false;
    } 
   
    let searchObj = this.state.searchTerm;
    searchObj.expiringWithinThree = 0;
    searchObj.secondarySearch = true
    searchObj.onlyValid = 0;
    searchObj.clickedSearch=1;
    searchObj.searchTermUpdated= true;
    searchObj.expiringWithinTwelve=0;
    searchObj.expired=0;
    searchObj.upcoming=1;
    searchObj.currentPage=1;
    let seacrObject = JSON.stringify(searchObj);
    localStorage.setItem('monitendSearchTerm', seacrObject);
    this.setState({
      searchTerm : searchObj,
      secondarySearch : true,
      clickedSearch:1,
      onlyValid : 0,
      searchTermUpdated: true,
      expiringWithinThree:0,
      expiringWithinTwelve:0,
      expired:0,
      upcoming:1,
    })
    e.preventDefault()
   }

   totalContractContracted=(e)=>{   
    
    var validOnlyChk = document.getElementById("valid_contracts_only_chk");
    if (validOnlyChk.checked) {
      validOnlyChk.checked = false;
    } 
    let searchObj = this.state.searchTerm;
    searchObj.expiringWithinThree = 0;
    searchObj.secondarySearch = true
    searchObj.onlyValid = 0;
    searchObj.clickedSearch=1;
    searchObj.searchTermUpdated= true;
    searchObj.expiringWithinTwelve=0;
    searchObj.expired=0;
    searchObj.upcoming=0;
    searchObj.currentPage=1;
    let seacrObject = JSON.stringify(searchObj);
    localStorage.setItem('monitendSearchTerm', seacrObject);
    this.setState({
      searchTerm : searchObj,
      secondarySearch : true,
      clickedSearch:1,
      onlyValid : 0,
      searchTermUpdated: true,
      expiringWithinThree:0,
      expiringWithinTwelve:0,
      expired:0,
      upcoming:0,
    })
    e.preventDefault()

   }

   validOnly=()=>{
    let onlyValid = this.state.onlyValid   

    let seacrhObj = this.state.searchTerm;
    seacrhObj.onlyValid = !onlyValid;
    this.setState({
        searchTerm : seacrhObj,
        onlyValid: !onlyValid
    })
    
   
    
  }
  regionWiseSales=()=>{
    let regionWise = this.state.regionWise
    let seacrhObj = this.state.searchTerm;
    seacrhObj.regionWise = !regionWise;
    this.setState({
        searchTerm : seacrhObj,
        regionWise: !regionWise
    })
  }
   resetForm=(event)=>{
     this.validOnly();
     this.handleProductChange(null);
        this.setState({
            selectedProduct: null,
            selectedSubstance : null,
            selectedVnr : null,
            selectedAtc:null,
            selectedCompany:null,
            selectedForm: null,
            selectedStrength: null,
            selectedSize:null,
            selectedToDate:"",
            selectedFromDate:"",
            selectedRegion:"",                    
        });
        
    }
    
    searchItems=(event)=>{
      let selectedProduct =  this.state.selectedProduct;
      let selectedSubstance =  this.state.selectedSubstance;
      let selectedVnr =  this.state.selectedVnr;
      let selectedAtc = this.state.selectedAtc;
      let selectedCompany = this.state.selectedCompany;
      let selectedStrength = this.state.selectedStrength;
      let selectedRegion = this.state.selectedRegion;
      let selectedSize = this.state.selectedSize;
      let selectedForm = this.state.selectedForm;
      let selectedToDate = this.state.selectedToDate;
      let selectedFromDate = this.state.selectedFromDate;
 
      if(
        (selectedProduct === '' && selectedSubstance === '' && selectedVnr === '' && selectedAtc === '' && selectedCompany === '' && selectedStrength === ''
        && selectedRegion === '' && selectedSize === '' && selectedForm === ''&& selectedToDate === '' && selectedFromDate === '') || (selectedProduct === null 
        && selectedSubstance === null && selectedVnr === null && selectedAtc === null && selectedCompany === null && selectedStrength === null
        && selectedRegion === '' && selectedSize === null && selectedForm === null && selectedToDate === '' && selectedFromDate === '')
      ){
        this.setState({
          formAlert:1,
        })
        this.toogleModal2();
        
        return false;

      }else{
        let searchTerm = {
          selectedProduct: selectedProduct,
          selectedSubstance : selectedSubstance,
          selectedVnr : selectedVnr,
          selectedAtc: selectedAtc,
          selectedCompany: selectedCompany,
          selectedStrength: selectedStrength,
          selectedRegion: selectedRegion,
          selectedSize: selectedSize,
          selectedForm: selectedForm,
          selectedToDate: selectedToDate,
          selectedFromDate: selectedFromDate,
          onlyValid: this.state.onlyValid,
          regionWise:this.state.regionWise,
          api_token : this.state.api_token,
          session:this.state.session,
          clickedSearch : 1,            
          currentPage:1,
          secondarySearch: this.state.secondarySearch,
          numberofRows:this.state.numberofRows

      }
      // let dowloadTerm = searchTerm;
      //     dowloadTerm.download = 1;

      //    let downloadSeacrObj = ""
      //     if(dowloadTerm !== "{}" && dowloadTerm !== 'undefined'&& dowloadTerm !== null  ){
      //       downloadSeacrObj = JSON.stringify(dowloadTerm);
      //     }
          
      //     let urlComponent = "'"+encodeURIComponent(downloadSeacrObj)+"'";
      //     dowloadURL = `${DOWNLOAD_URL}?term=${urlComponent}`
           
          
     let eventObj = {
        articleId: 1, //since we have only one page
        url: `${API_URL}search`,
        marker: 'article_read',
        title: 'Search',
        article_param1: {selectedSubstance},
        article_param2: {selectedVnr},
        article_param3: {selectedCompany},
        article_param4: {selectedRegion},
        article_param5: {selectedAtc}
      }
     
      this.setState({
          searchTerm: searchTerm,
          clickedSearch: searchTerm.clickedSearch,
          secondarySearch:searchTerm.secondarySearch,
          run2:true,
          expiringWithinThree:0,
          expiringWithinTwelve:0,
         
      })

      if(this.state.tutorialStarted){
        
        this.setState({
          currentStep:this.state.currentStep+1
        })
        this.toggleInfoDivClass()
      }
      
      let seacrObject = JSON.stringify(searchTerm);
      localStorage.setItem('monitendSearchTerm', seacrObject);
      this.setState({
        secondarySearch:true,
      })
      
      axios.post(`${API_URL}get_contract_info`, {
        seacrhObj: seacrObject,
        token:token
      }).then(response => {
          if(response.status === 200 && (Object.keys(response.data.data)).length >0){
            this.setState({
              contractInfo:response.data.data
            })
          } 
        }
       
      ).catch((error) => {
        document.location.href="/";
      })
      

    //prodData

    // let urlProd = `${API_URL}search?seacrhObj=${seacrObject}`;
    //     axios.post(urlProd).then(response => {
    //       if(response.status === 200 && response.data.data.length >0){
            
    //         prodData = response.data.data;
            
    //     }else{
    //       prodData = [];
    //       } 
    //     }).catch( (err) => {
    //       document.location.href="/";
          
    //     })
      
        termUpdate = true;
        
        this.toggleClass();
      }

      ReactGA.event({
        category:'Search',
        action:'Search Records'
      })
        
     
      if(event){
        event.preventDefault();
      }
      
    }
   
    
   
    formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    handleOnToDateSelect= selectedToDate=>{

      if(selectedToDate !== null){
        let toDate = new Date(this.formatDate(selectedToDate));
        this.setState({selectedToDate:toDate});
      }else{
        this.setState({selectedToDate:""});
      }
        
        
        
    }

    handleOnFromDateSelect= selectedFromDate=>{
      if(selectedFromDate !== null){
        let fromDate = new Date(this.formatDate(selectedFromDate));
        this.setState({selectedFromDate:fromDate});
      }else{
        this.setState({selectedFromDate:""});
      } 
    }
   
    handleProductChange = selectedProduct => {
      this.setState({ selectedProduct });
    };

    getAtcCodeFromSubstance = selectedSubstance=>{
      let getAtcsUrl = `${API_URL}get_atc_from_subtance?substance=${selectedSubstance}`;
      let delayFunc;
      axios.post(getAtcsUrl).then(response => {
        if(response.status === 200 && (Object.keys(response.data.data)).length >0){
          if(response.data.data.length>0){
            let atc_array = [];
            Object.entries(response.data.data).forEach(([key, value]) => {          
              atc_array.push(value)
              // console.log(value)
              // this.handleAtcChange(value)
            })
            for(let j=0; j<atc_array.length-1; j++){              
              delayFunc = setTimeout(this.handleAtcChange(atc_array[j]), 1000)
            }
           
          }
        } 
      })
      
      clearTimeout(delayFunc)

      
    }

    handleSubstanceChange = selectedSubstance=>{
      // if(selectedSubstance !== null && selectedSubstance !== undefined){
      //   this.getAtcCodeFromSubstance(selectedSubstance[0].value)
      //   this.setState({ selectedSubstance });
        
      // }else{
      //   this.setState({ selectedSubstance }); 
      // }
      
      this.setState({ selectedSubstance }); 
      
      // if(this.state.tutorialStarted){
      //   if(this.state.selectedSubstance !== "") {
      //     document.getElementById("next_done_btn").disable = false;
      //   }
      //  }
    }

    handleVnrChange = selectedVnr=>{
        this.setState({selectedVnr});
    }
    handleAtcChange = selectedAtc=>{
      
        this.setState({selectedAtc});
    }
    handleCompanyChange = selectedCompany=>{
        this.setState({selectedCompany});
        
    }
    handleFormChange = selectedForm=>{
        this.setState({selectedForm});
    }
    handleStrengthChange = selectedStrength=>{
        this.setState({selectedStrength});
        
    }
    handleSizeChange = selectedSize=>{
        this.setState({selectedSize});
        
    }
    handleRegionChange = selectedRegion=>{
      this.setState({selectedRegion})
    }

    resizeSecondTable=(from='')=>{
      let sideBarType = ""
      if(this.state.active){
        sideBarType = "slim"
      }else{
        sideBarType = "wide"
      }

     
      var elementExists = document.getElementById("parent_div_1");
    
      if(elementExists !== null){
        var mainWindow = document.getElementById('dashboardWindow').offsetWidth;
        var search_pannel = (sideBarType === 'slim')?3:25;
        if(from === 'from_right_panel'){
          search_pannel = 3;
        }
        var widthDiv1 = document.getElementById('parent_div_1').offsetWidth;
        var widthDiv1InVw = (100/mainWindow)*widthDiv1;
        var widthDiv2 = "";
        widthDiv2 = (100-(widthDiv1InVw));

        
        //widthDiv2 = mainWindow-widthDiv1
        document.getElementById('parent_div_2').style.maxWidth = widthDiv2+"vw";
        
      }

    }
    toggleClass = () => {
     
     
     const currentState = this.state.active;
     this.setState({ active: !currentState});
     //this.resizeSecondTable();
        
  }
    toggleInfoDivClass=()=>{

      const currentState = this.state.infoDivactive;
      this.setState({ infoDivactive: !currentState, rightPanClicked:!this.state.rightPanClicked});
      //this.resizeSecondTable('from_right_panel');
      
    }

    loadNotice=()=>{
      axios.get(`${API_URL}get_notice`,{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem('api_token'),
        },
      }).then(response => {
        if(response.status === 200 && (Object.keys(response.data.data)).length >0){
          this.setState({
            noticeInfo:response.data.data[0]
          })
        } 
      }).catch( (err) => {
       
        this.setState({
          loggedInStatus: 'NOT_LOGGED_IN'
        })
     
      })

    }
    loadTooltips=()=>{

      axios.get(`${API_URL}get_all_tool_tips`,{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem('api_token'),
        },
      }).then(response => {
        
        let tips = [];
        let cities = [];
        if(response.status === 200 && (Object.keys(response.data.data)).length >0){
          response.data.data.forEach(element => {
            let munId = element.municipality_id
            console.log(element.show)
            if(String(element.show) === "1"){
              tips[response.data.regions[munId][0]] = element.tip;
              cities.push(response.data.regions[munId][0]); 
            }
            
          });
        }
        this.setState({
          tootlTips: tips,
          arrayRegion:cities
        }) 
      }).catch( (err) => {
       
        this.setState({
          loggedInStatus: 'NOT_LOGGED_IN'
        })
     
      })

    }
  
    componentDidMount(){
      
      initReactGA();
      loadPageView();
      this.loadNotice();
      this.loadTooltips();
      
      var vh=document.getElementById('vh');
      screenHeight = vh.clientHeight;
      let tableHeight = 85; // 86 vh: 100vh total screen; 6vh navigation, 2 vh footer, 4 vh tabs height, 1 view height for spacing and 1 vh for safety
      tableHeightPx = (screenHeight/100)*tableHeight;
      
      this.setState({
        tableHeight:tableHeightPx
      })
      
        axios.post(`${API_URL}get_update_info`,{
          token:token
        }).then(response => {
          if(response.status === 200 && (Object.keys(response.data.data)).length >0){
            this.setState({
              updateInfo:response.data.data
            })
          } 
        }).catch( (err) => {
         
          this.setState({
            loggedInStatus: 'NOT_LOGGED_IN'
          })
       
        })
          axios.post(`${API_URL}user`, {
            api_token: token
          }).then(response => {
            if(response.status === 200 && (Object.keys(response.data.data)).length >0){
              if(Number(response.data.data.tutorial_active) ===1 && Number(response.data.data.seen_once) === 0){
                tutorialReady = 1;
              }
              this.setState({
                  tutorialActive: response.data.data.tutorial_active,
                  seenOnce: response.data.data.seen_once
              })
            }
          }).catch((error) => {
            this.setState({
              loggedInStatus: 'NOT_LOGGED_IN'
            })
          })

          
      
      
    }

   

    getSessionValue(sessionName){
      let value = ""
      let match = document.cookie.match(new RegExp('(^| )' + sessionName + '=([^;]+)'));
      if (match) {
        value = match[2];
      }
      return value;
    }

    checkLoginStatus(){
      let userid = localStorage.getItem('monitendUser');
      let apiToken = localStorage.getItem('api_token');
      let MNSession = this.getSessionValue('MNSession');
      let loggedIn = true;
      let user = {};
      if(userid !== null && apiToken !== null){
        
        axios.post(`${API_URL}user`,{
          api_token:apiToken
        }).then(response => {
          
          if(!response.data.data){
            document.location.href="/";
          }
         
        }).catch( (err) => {
          
          document.location.href="/";
      })
      
    }
  }
    componentDidUpdate(prevProps){
      
     
      
      if(this.state.searchTermUpdated){
        termUpdate = true
        clickedSearch  = this.state.clickedSearch;
        secondarySearch = this.state.secondarySearch;
        expiringWithinThree = this.state.expiringWithinThree;
        expiringWithinTwelve = this.state.expiringWithinTwelve;
        expired = this.state.expired;
        upcoming = this.state.upcoming;
        onlyValid = this.state.onlyValid;
        searchTermUpdated = true;
      }

      
    }
    componentWillMount(){
      
      if(this.state.loggedInStatus === 'NOT_LOGGED_IN'){
          document.location.href="/";
      }
      if(this.state.loggedInStatus === 'LOGGED_IN'){
        if (window.performance) {
          if (performance.navigation.type === 1) {
            let searchTerm = {};
            let seacrObject = JSON.stringify(searchTerm);
            localStorage.setItem('monitendSearchTerm', seacrObject);
            document.location.href="/";
          } 
        }
      }
      const search = localStorage.getItem('monitendSearchTerm');
      let searchObj = JSON.parse(search);
      let searchObject = {}
      if(searchObj.clickedSearch === 1){
        searchObject = JSON.stringify(searchObj);
        this.toggleClass()

      }else{
        let emptyTerm = {};
        let emptySearch = {};
        emptyTerm.onlyValid = this.state.onlyValid
        emptyTerm.regionWise = this.state.regionWise
        emptyTerm.clickedSearch = this.state.clickedSearch
        emptySearch.searchTerm = emptyTerm
        searchObject = JSON.stringify(emptyTerm);
      }
      
      
      axios.post(`${API_URL}get_contract_info`, {
        seacrhObj: searchObject,
        token:token
      }).then(response => {
            if(response.status === 200 && (Object.keys(response.data.data)).length >0){
              
              this.setState({
                contractInfo:response.data.data
              })
            }
                
        }).catch((error) => {
          //document.location.href="/";
          this.setState({
            loggedInStatus: 'NOT_LOGGED_IN'
          })
        })
      
        
       
    }
    
    render() {

      
      const { updateInfo,  contractInfo,selectedToDate,selectedFromDate, searchTerm,stepsEnabled, steps,allCodeAccess, formAlert,showHelp,currentStep, tutorialActive,seenOnce,tutorialStarted, autoTutorial,tutorial_active, seen_once} = this.state;        
      if(tutorialReady ===1){
        this.startTutorial();
      }
      
      if(tutorialStarted){
        let nonCurtainSteps = [4,5,6,8,9,11]
   
        if(nonCurtainSteps.includes(currentStep)){
            
          let prompt = document.querySelectorAll('.tutorial-prompt')
    
          if(prompt.length>0){
            prompt.forEach(element => {
              element.parentElement.removeChild(element);    
              let curtainElem = document.querySelectorAll('.tutorial-curtain')[0]
              if(curtainElem){
                curtainElem.parentElement.removeChild(curtainElem)
              }
        
              let tutorialElem = document.querySelectorAll('.tutorial-element')[0]
              if (tutorialElem){
              tutorialElem.classList.remove('tutorial-element');
              }
              
            });
          }
          let tutorialELem = document.querySelectorAll('.tutorial-element')
          
        
          
        }
        
        if(currentStep === 7){
          //
          let rightElem = document.getElementById("rightSideInfo")
          if(rightElem.classList.contains("update_info_toggled")){
            let currentState = this.state.infoDivactive;
            this.setState({ infoDivactive: !currentState, rightPanClicked:!this.state.rightPanClicked});
            rightElem.setAttribute("style","z-index:2011;")
            rightElem.classList.remove('update_info_toggled');
            rightElem.classList.add('update_info');
            let contractElement = document.querySelectorAll('.contract_status_container_toogled')[0];
            if(contractElement){
              contractElement.classList.remove('contract_status_container_toogled');
              contractElement.classList.add('contract_status_container');              
            }
            let contractList = document.querySelectorAll('.contract-list_toggled')[0];
            if(contractList){
              contractElement.classList.remove('contract-list_toggled');
              contractElement.classList.add('contract-list');  
            }
            let updateList = document.querySelectorAll('.status-list_toggled')[0];
            if(updateList){
              contractElement.classList.remove('status-list_toggled');
              contractElement.classList.add('status-list');  
            }
          }
        }
       
        if( nonCurtainSteps.includes(currentStep) ){
          let rightElem = document.getElementById("rightSideInfo")
          if(rightElem.classList.contains("update_info")){
            let currentState = this.state.infoDivactive;
            this.setState({ infoDivactive: !currentState, rightPanClicked:!this.state.rightPanClicked});
            rightElem.setAttribute("style","z-index:2011;")
            rightElem.classList.remove('update_info');
            rightElem.classList.add('update_info_toggled');
            let contractElement = document.querySelectorAll('.contract_status_container')[0];
            if(contractElement){
              contractElement.classList.remove('contract_status_container');
              contractElement.classList.add('contract_status_container_toogled');              
            }
            let contractList = document.querySelectorAll('.contract-list')[0];
            if(contractList){
              contractElement.classList.remove('contract-list');
              contractElement.classList.add('contract-list_toggled');  
            }
            let updateList = document.querySelectorAll('.status-list')[0];
            if(updateList){
              contractElement.classList.remove('status-list');
              contractElement.classList.add('status-list_toggled');  
            }
          }
        }
        


        if(this.state.currentStep ===8){
          document.getElementById('product_tab_div').click();        
        }
        if(this.state.currentStep ===9){
          document.getElementById('sales_unit_tab_div').click();        
        }
        if(this.state.currentStep ===10){
        let searchPannel = document.getElementById("search_pannel")
        if(searchPannel){
          if(searchPannel.classList.contains('grid_item1_toggled')){
            searchPannel.classList.remove("grid_item1_toggled");
            searchPannel.classList.add("grid_item1");
            this.toggleClass();
          }
        }
        let curtainElement = document.querySelectorAll('.tutorial-curtain')[0];
          if(curtainElement){
            curtainElement.setAttribute("style", "left:25 !important;"); 
          }
        }
        
        let curtainElement = document.querySelectorAll('.tutorial-curtain');
        if(curtainElement.length === 0){
          let searchProdInfo = document.querySelectorAll('.no_data_found')[0];
          if(searchProdInfo){
            searchProdInfo.setAttribute("style", "margin-top: 30%;");          
          }
          this.getElementByStage(currentStep);
        }
        
      }

        
        let searchT = localStorage.getItem('monitendSearchTerm');


        let apiToken = localStorage.getItem('api_token');
        let downloadSeacrObj = "{}";
        if(searchT !== "{}" && searchT !== 'undefined'&& searchT !== null  ){
          let termForDownload = JSON.parse(searchT);
          downloadSeacrObj = JSON.stringify(termForDownload);
        }
        
        let urlComponent = "'"+encodeURIComponent(downloadSeacrObj)+"'";
        let downloadUrl = `${DOWNLOAD_URL}?term=${urlComponent}`
        
        let downloadAble = false;
        if(searchT !== "{}" && searchT !== 'undefined'&& searchT !== null  ){
          downloadAble = true;
        }
        let searchObj = JSON.parse(searchT);

       
        let searchItem = this.state.searchTerm        
        
        let clickedSearch =  this.state.clickedSearch;
      
        let clickedDownload = this.state.clickedDownload;
        
        if(searchTermUpdated || termUpdate){
          clickedSearch = (searchT !== "{}") ? this.state.clickedSearch : 0;
          secondarySearch = (searchT !== "{}") ?this.state.secondarySearch:false ;
          expiringWithinThree = (searchT !== "{}") ? this.state.expiringWithinThree:0;
          expiringWithinTwelve = (searchT !== "{}") ? this.state.expiringWithinTwelve:0;
          expired = (searchT !== "{}") ? this.state.expired:0;
          upcoming = (searchT !== "{}") ? this.state.upcoming:0;
          onlyValid = (searchT !== "{}") ? this.state.onlyValid:0;
          searchItem = searchObj;
        }
        
        //console.log(this.state.searchTerm, secondarySearch)
        
        // let updateInfoArr = updateInfo.split(",");
        let splittedInfo = [];
        let listItems = Object.entries(updateInfo).forEach(([key, value]) => {
          
          splittedInfo.push({date:value, place:key})
        })
        let InfoExists = 0;
        let renderNoticeInfo= ""
        if(this.state.noticeInfo.notice_info !== undefined && String(this.state.noticeInfo.show) === "1"){
            InfoExists = 1;
            renderNoticeInfo = (
              <li className="nonHiglighted listItems noticeDiv"> 
                  <span className="info_date noticeInfo" style={{"margin":"0px 1px 0px 0px","float":"left"}}>{this.state.noticeInfo.notice_info}</span>
              </li>
            )
          
        }
        

        
          
        let renderUpdateInfo = splittedInfo.map((info, index)=>{
          
          let region = info.place.charAt(0).toUpperCase() + info.place.slice(1); 
          if(this.state.arrayRegion.includes(region)){
            if(info.place!==" " && info.date !=="undefined"){
              return(
                <li  style={{"lineHeight": "10px","padding": "8px","width": "100%"}}className="nonHiglighted listItems tooltipTwo" key={index}>
                  <span style={{"float": "left"}}className="info_region">{infoIcon} {info.place.charAt(0).toUpperCase() + info.place.slice(1)}</span>
                  <span style={{"float": "right"}} className="info_date">{info.date}</span>
                  <span className="tooltipTwotext">{this.state.tootlTips[region]}</span>                
                </li>
              )
            }

            

          }else{
            if(info.place!==" " && info.date !=="undefined"){
              return(
                <li  className="nonHiglighted listItems" key={index}>
                  <span className="info_region">{info.place.charAt(0).toUpperCase() + info.place.slice(1)}</span>
                  <span className="info_date">{info.date}</span>                
                </li>
              )
            }

          }
          
          
        })
       
        
        let clickAble = false;
        if(secondarySearch || clickedSearch ===1 ){
          clickAble = true;
        }
        
        let mainInfoDivClass= ""
        if(this.state.active && this.state.infoDivactive){
          mainInfoDivClass = "main_info_div";
        }else if(this.state.active === false && this.state.infoDivactive){
          mainInfoDivClass = "main_info_div_toogled_one";
        }else if(this.state.active === false && this.state.infoDivactive===false){
          mainInfoDivClass = "main_info_div_toogled";
        }else if(this.state.active && this.state.infoDivactive===false){
          mainInfoDivClass = "main_info_div_toogled_two";
        }
        let markValidOrAll = ""
        if(onlyValid &&!expiringWithinThree && !expiringWithinTwelve && clickedSearch){
          markValidOrAll = "validContract";
        }else if(!onlyValid && expiringWithinThree && !expiringWithinTwelve && clickedSearch){
          markValidOrAll = "expiringWithinThree"
        }else if(!onlyValid && !expiringWithinThree && expiringWithinTwelve && clickedSearch){
          markValidOrAll = "expiringWithinTwelve"
        }else if(expired && !onlyValid && !expiringWithinThree && !expiringWithinTwelve && clickedSearch){
          markValidOrAll = "expired"
        }else if(upcoming && clickedSearch){
          markValidOrAll = "upcoming"
        }else if(!onlyValid &&!expiringWithinThree && !expiringWithinTwelve && clickedSearch && !upcoming){
          markValidOrAll = "all"
        }        //let markValidOrAll = (onlyValid &&!expiringWithinThree && !expiringWithinTwelve)? "validContract":"totalContract";
        
        let helpLink = ""

        if(clickedSearch ){
          exportActive = true;
          
        }
        if((clickedSearch && clickedDownload ===1)|| (clickedSearch === false && clickedDownload ===0)){
          exportActive =false;
        }
        let downLoadLink;
        let disableLink;
        
          disableLink = (
            <NavLink className="not_clickable" href="#" >
           
              {download} Download
           
            </NavLink>
          )
       
        
          
        downLoadLink = downloadUrl;
        
      
      
      if(Number(tutorialActive) === 1){
        helpLink = (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
            { question } Help
            </DropdownToggle>
            <DropdownMenu right id="dropdown_help">
              <DropdownItem>
                <NavLink className="navlink-text" href="#" onClick={this.showHelpModal}>{ question } Use of monitend </NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
              <NavLink className="navlink-text" href="#" onClick={this.startTutorial}>{ bookOpen } Monitend tutorial</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }else{
        helpLink = (
          <NavItem className="navitem-border navitem_responsive">
              <NavLink className="navlink-text" href="#" onClick={this.showHelpModal}>{ question } Help</NavLink>
          </NavItem>
        )
      }
      let modalBodyHelp = "";

      if(showHelp){
        modalBodyHelp = (
            <Modal style=  {{maxWidth: '1600px', width: '80%'}}  isOpen={this.state.modal} toggle={this.toogleModal}>
              <ModalHeader  toggle={this.toogleModal} charCode="x" className="modal_title">Use of monitend</ModalHeader>
              <HelpModal/>
              <ModalFooter> 
                <button onClick={this.toogleModal} type="button" className="form-button">Ok</button>                 
              </ModalFooter>
            </Modal>
        )
      }
      let modalBodyFormAlert = "";
      if(formAlert){
        modalBodyFormAlert = (
          
          <Modal style=  {{maxWidth: '500px', width: '500px'}}  isOpen={this.state.modal2} toggle={this.toogleModal2}>
              <ModalHeader  toggle={this.toogleModal2} charCode="x" className="modal_title">Empty search form!</ModalHeader>
              <div className="form_alert"><p> You must type in at least one search parameter.</p></div>
              <ModalFooter> 
                <button onClick={this.toogleModal2} type="button" className="form-button">Ok</button>                 
              </ModalFooter>
            </Modal>
        )
      }
      let tablist = ""
      let tabContents = ""
      
        tablist = (
          <TabList>
            <Tab id='productWithContract'>
                <div id="pod_contract_tab_div">
                    <span className="tab_header">PRODUCTS WITH CONTRACTS</span>
                    <span className="tab_header_icon">{ fileContract }</span>
                </div>
            </Tab>
            <Tab id='product'>
            <div id="product_tab_div">
                <span className="tab_header">PRODUCTS</span>
                <span className="tab_header_icon">{pills}</span>
                </div>
                
            </Tab>
            
            <Tab id='salesUnit'>
            <div id="sales_unit_tab_div">
            <span className="tab_header">SALES - UNITS</span>
                <span className="tab_header_icon">{boxes}</span>
                </div>
                
            </Tab>
            <Tab id='salesValue'>
            <div id="sales_val_tab_div">
            <span className="tab_header">SALES - VALUE</span>
                <span className="tab_header_icon">{coins}</span>
                </div>
                
            </Tab>
                            
          </TabList>
        )
        tabContents=(
          <div className="tab_contents">
              <TabPanel>
                  <ProductContracts  searchTerm={searchItem} tutorialStarted={this.state.tutorialStarted} />
              </TabPanel>
              <TabPanel>
              <Products  searchTerm={searchItem} tutorialStarted={this.state.tutorialStarted} />
                  
              </TabPanel>
              <TabPanel>
              <SalesVolume  searchTerm={searchItem} tutorialStarted={this.state.tutorialStarted} />
              </TabPanel>
              <TabPanel>
              <SalesValue  searchTerm={searchItem} tutorialStarted={this.state.tutorialStarted} />
              </TabPanel>
              
          </div>
        )
      

        return (
          <div>
            <div className="main_container" id="vh" style={{height:"100vh"}}>
             <div className="drop_down_btn"></div>
                {/* <Navigation navProps={this.state} /> */}
                <div id="top_nav">
                <div  id="navigation" className="navigation">
                  <Navbar color="light" light expand="md" className="slim_nav" >
                    <NavbarBrand className="nav-logo-holder" href="/">
                          <img src={logo} alt="Logo" />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>

                      <Nav className="ml-auto">
                      
                        {helpLink}
                        
                        <NavItem id="iFrame" className="navitem-border navitem_responsive">
                            
                          {(downloadAble)? 
                          (this.state.showSpinner?                            
                              <div  style={downLoadStyle.spinning}>{spinner} Download</div> 
                              :
                              <NavLink className="navlink-text" onClick={this.startDownload} href="#">{ download } Export</NavLink>
                          )

                          
                          :disableLink} 
                          
                        </NavItem>
                        <NavItem className="navitem-border navitem_responsive">
                          <NavLink className="navlink-text" href="#" onClick={this.handelLogout}>{ signout } Logout</NavLink>
                        </NavItem>
                      </Nav>
                    </Collapse>
                  </Navbar>
                
                  </div>
                  
              </div>
                <div className="dashboard" id="dashboardWindow" >
                  <div id="empty_selector"></div>
                <div id="search_pannel" onMouseOut={this.mouseOutLeftPannel} onMouseOver={this.hoverEffectArraow} onClick={this.toogleLeftPannel} className={this.state.active ? 'grid_item1': 'grid_item1_toggled'} >
                  <div id="search_button" >
                    <div id="walkthrough_search_div" className="walkthrough_search_div"> 
                      <div id="search_button_effect" className={this.state.active ? 'search-button': 'search-button_toggled'} onClick={this.toggleClass}>{ this.state.active ? LeftArraow:search}</div>
                    </div>                    
                  </div>
                    <div className={this.state.active ? 'show_pannel': 'hide_pannel'}>
                        <div className={this.state.active ? 'search_form': 'search_form_toogled'}>
                        <Form id="search_form" autoComplete="off">
                            <div className="inputFields">
                                <div className="full_width">
                               
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{tablets}</div></InputGroupAddon>
                                        <div className="big_input plugin">
                                        <AsyncSelect 
                                          isMulti
                                          value={this.state.selectedProduct}
                                          cacheOptions
                                          defaultOptions
                                          components={{ DropdownIndicator:() => null }}
                                          placeholder ="Product"
                                          //loadOptions={this.getAsyncOptions}
                                          noOptionsMessage= {() => "Type to select"}
                                          onChange={this.handleProductChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "product")}
                                        />
                                        </div>
                                    </InputGroup>
                                </div>

                                <div className="full_width" id="guided_tour1">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><img src={molecule} alt="molecule"  className="form_lablel_img"/></InputGroupAddon>
                                        <div className="big_input plugin" >
                                          <AsyncSelect 
                                          isMulti
                                          value={this.state.selectedSubstance}
                                          cacheOptions
                                          placeholder ="Substance"
                                          components={{ DropdownIndicator:() => null }}
                                          //loadOptions={this.getAsyncOptions}
                                          noOptionsMessage= {() => "Type to select"}
                                          onChange={this.handleSubstanceChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "substance")}
                                        />
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="full_width">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><img src={nan} alt="nan"  className="form_lablel_img"/></InputGroupAddon>
                                       
                                        <div className="big_input plugin">
                                          <AsyncSelect
                                          isMulti
                                          cacheOptions
                                          value={this.state.selectedVnr}
                                          placeholder ="Nordic Article Number"
                                          components={{ DropdownIndicator:() => null }}
                                          noOptionsMessage= {() => "Type to select"}
                                          //loadOptions={this.getAsyncOptions}
                                          onChange={this.handleVnrChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "vnr")}
                                        />
                                        </div>
                                    </InputGroup>
                                </div>

                                <div className="full_width">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><img src={atcIcon} alt="molecule"  className="form_lablel_img"/></InputGroupAddon>
                                        <div className="big_input plugin">
                                          <AsyncSelect 
                                          isMulti
                                          cacheOptions
                                          value={this.state.selectedAtc}
                                          placeholder ="ATC"
                                          components={{ DropdownIndicator:() => null }}
                                          noOptionsMessage= {() => "Type to select"}
                                          //loadOptions={this.getAsyncOptions}
                                          onChange={this.handleAtcChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "atc")}
                                        />
                                        </div>
                                    </InputGroup>
                                </div>

                                <div className="full_width">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{companyIcon}</div></InputGroupAddon>
                                        <div className="big_input plugin">
                                          <AsyncSelect
                                          isMulti
                                          cacheOptions
                                          value={this.state.selectedCompany}
                                          placeholder ="Company"
                                          components={{ DropdownIndicator:() => null }}
                                          noOptionsMessage= {() => "Type to select"}
                                          //loadOptions={this.getAsyncOptions}
                                          onChange={this.handleCompanyChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "company")}
                                        />
                                        </div>
                                        
                                    </InputGroup>
                                </div>
                                <div className="full_width">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{forms}</div></InputGroupAddon>
                                        <div className="big_input plugin">
                                          <AsyncSelect
                                          isMulti
                                          cacheOptions
                                          value={this.state.selectedForm}
                                          placeholder ="Form"
                                          components={{ DropdownIndicator:() => null }}
                                          noOptionsMessage= {() => "Type to select"}
                                          //loadOptions={this.getAsyncOptions}
                                          onChange={this.handleFormChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "form")}
                                        />
                                        </div>
                                        
                                    </InputGroup>
                                </div>
                                <div className="full_width">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{area}</div></InputGroupAddon>
                                        <div className="big_input plugin">
                                          <AsyncSelect 
                                          isMulti
                                          cacheOptions
                                          value={this.state.selectedRegion}
                                          placeholder ="Region"
                                          components={{ DropdownIndicator:() => null }}
                                          noOptionsMessage= {() => "Type to select"}
                                          //loadOptions={this.getAsyncOptions}
                                          onChange={this.handleRegionChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "region")}
                                        />
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="full_width">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{strengthIcon}</div></InputGroupAddon>
                                        
                                        <div className="big_input plugin">
                                          <AsyncSelect 
                                          isMulti
                                          cacheOptions
                                          placeholder ="Strength"
                                          components={{ DropdownIndicator:() => null }}
                                          noOptionsMessage= {() => "Type to select"}
                                          value={this.state.selectedStrength}
                                          //loadOptions={this.getAsyncOptions}
                                          onChange={this.handleStrengthChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "strength")}
                                        />
                                        </div>
                                    </InputGroup>
                                    
                                    
                            
                                </div>
                                <div className="full_width">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic"><div className="form_lablel_img">{scale}</div></InputGroupAddon>
                                        {/* <div className="input_div_half"> 
                                        
                                        <SizeInput />
                                        </div> */}
                                        
                                        <div className="big_input plugin">
                                          <AsyncSelect
                                          isMulti
                                          cacheOptions
                                          placeholder ="Size"
                                          components={{ DropdownIndicator:() => null }}
                                          noOptionsMessage= {() => "Type to select"}
                                          value={this.state.selectedSize}
                                          //loadOptions={this.getAsyncOptions}
                                          onChange={this.handleSizeChange}
                                          loadOptions={inputValue => this.debouncedLoadOptions(inputValue, "size")}
                                        />
                                        </div>
                                    </InputGroup>
                                </div> 
                                <div className="half_width ">
                                
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic_in_half"><div className="form_lablel_img_half">{calendarDay}</div></InputGroupAddon>
                                        <div id="last_date_picker"className="date_picker plugin">
                                        <DatePicker
                                        showPopperArrow={false}
                                        selected={selectedFromDate}
                                        onChange={this.handleOnFromDateSelect}
                                        dateFormat = {"yyyy-MM-dd"}
                                        placeholderText="Contract from"
                                        popperPlacement="top-end"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ['top'] // don't allow it to flip to be above
                                            },
                                            preventOverflow: {
                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                            },
                                            offset: {
                                              enabled: true,
                                              offset: "70px, 10px"
                                            },
                                            hide: {
                                                enabled: false // turn off since needs preventOverflow to be enabled
                                            }
                                        }}
                                        
                                        style={{
                                          margin: 10,
                                          display: "flex",
                                          justifyContent: "center"
                                        }}
                                    />

                                        </div>
                                    </InputGroup>
                                </div> 
                                <div className="half_width last_item">
                               
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType="prepend" className="form_label_pic_in_half"><div className="form_lablel_img_half">{calendarDay}</div></InputGroupAddon>
                                        <div id="second_date_picker" className="input_div_half date_picker"> 
                                       
                                        <DatePicker 
                                        showPopperArrow={false}
                                        selected={selectedToDate}
                                        onChange={this.handleOnToDateSelect}
                                        dateFormat = {"yyyy-MM-dd"}
                                        placeholderText="Contract to"
                                        popperPlacement="top-end"
                                        popperModifiers={{
                                          
                                          preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport"
                                          }
                                        }}
                                         style={{
                                          margin: "10 20 10 10",
                                          display: "flex",
                                          justifyContent: "center"
                                        }}
                                    />
                                        </div>
                                    </InputGroup>  
                            
                                </div>
                                </div>
                                <div className="half_width valid_only_checkbox">  
                                <label><Input id="valid_contracts_only_chk" type="checkbox" defaultChecked={this.state.onlyValid} onChange={this.validOnly} /><span className="checkboxText">Valid contracts only</span></label>

                                </div>

                                <div className="half_width region_wise_checkbox">  
                                <label><Input id="region_wise_chk" type="checkbox" defaultChecked={this.state.regionWise} onChange={this.regionWiseSales} /><span  className="checkboxText">Region-wise sales data</span></label>

                                </div>
                                
                                
                                                  
                                <div id="form_buttons" className={this.state.active ? 'buttons': 'buttons_toggled'}>
                                <button type="button" id="submit_form" className="form-button"  onClick={this.searchItems}>Search</button>
                                    <button type="button" id="reset_form" className="form-button" onClick={this.resetForm}>Reset</button>
                                    
                                    {/* <button type="button" className="form-button">Save search</button> */}                                   
                                </div>
                            </Form>   
                                                
                        </div>
                        
                        

                    </div>
                </div>
                <main id="main">
                  <div className={mainInfoDivClass}>
                    <div className="tab_container" id="tab_container">
                    <Tabs className="tabs_responsive">
                        <div className="tab_list" id='walkthrough_salesval'>
                             {tablist}
                        {/* sampleTab */}
                        </div>
                        
                        {tabContents}
                       
                    </Tabs>
                    </div>
                </div>
                
                </main>
                <aside id="aside">
                <div id="rightSideInfo" className={this.state.infoDivactive ? 'update_info zindex_3': 'update_info_toggled zindex_3'}>

                  <div  className={this.state.infoDivactive ? 'contract_status_container': 'contract_status_container_toogled'}>
                            <div className="infotag" onClick={this.toggleInfoDivClass} ></div>
                            <ol className={this.state.infoDivactive ? 'contract-list': 'contract-list_toggled'}>
                                    <li> Contracts</li>
                                
                                    <li className={(markValidOrAll==="validContract") ? "highLightLi listItems":"nonHiglighted listItems"}>
                                        <span className="info_title">Valid contracts: </span>
                                        <span className="info_number">{(clickAble && contractInfo.valid>0) ?<a href="" onClick={this.validOnlyContracts} className='highlightText'>{contractInfo.valid}</a>: contractInfo.valid }</span>
                                    </li>
                                    <li  className={(this.state.expiringWithinThree ===1) ? "highLightLi listItems":"nonHiglighted listItems"}>
                                        <span className="info_title">Contracted products: <i>expiring within 3 months</i></span>
                                        <span className="info_number">{(clickAble && contractInfo.expiringIn3>0) ? <a href="" onClick={this.expiringWithin3} className='highlightText'>{contractInfo.expiringIn3}</a>: contractInfo.expiringIn3 }</span>
                                    </li>

                                    <li className={(this.state.expiringWithinTwelve ===1) ? "highLightLi listItems":"nonHiglighted listItems"}>
                                        <span className="info_title">Contracted products: <i>expiring within 12 months</i></span>
                                        <span className="info_number">{(clickAble && contractInfo.expiringIn12>0) ? <a href="" onClick={this.expiringWithin12} className='highlightText'>{contractInfo.expiringIn12}</a>: contractInfo.expiringIn12 }</span>
                                    </li>
                                    
                                    <li className={(markValidOrAll==="expired") ? "highLightLi listItems":"nonHiglighted listItems"}>
                                      <span className="info_title">Contracted products: <i>expired</i></span>
                                      <span className="info_number">{(clickAble && contractInfo.expierdContract>0) ? <a href="" onClick={this.expiredContracts} className='highlightText'>{contractInfo.expierdContract}</a>: contractInfo.expierdContract }</span>
                                    </li>
                                    <li className={(markValidOrAll==="upcoming") ? "highLightLi listItems":"nonHiglighted listItems"}>
                                      <span className="info_title">Upcoming products contracted: </span>
                                      <span className="info_number">{(clickAble && contractInfo.upcoming>0) ? <a href="" onClick={this.upcomingConntract} className='highlightText'>{contractInfo.upcoming}</a>: contractInfo.upcoming }</span>
                                      </li>
                           
                                    <li className={(markValidOrAll==="all" ) ? "highLightLi listItems":"nonHiglighted listItems"}>
                                      <span className="info_title">Total number of contracts: </span>
                                      <span className="info_number">{(clickAble && contractInfo.total>0) ? <a href="" onClick={this.totalContractContracted} className='highlightText'>{contractInfo.total}</a>: contractInfo.total }</span>
                                      
                                    </li>
                                    <li className={(markValidOrAll==="all") ? "highLightLi listItems":"nonHiglighted listItems"}>
                                        <span className="info_title">Total contracted products: </span>
                                        <span className="info_number">{(clickAble && contractInfo.totalContracted>0) ? <a href="" onClick={this.totalContractContracted} className='highlightText'>{contractInfo.totalContracted}</a>: contractInfo.totalContracted }</span>
                                        
                                    </li> 
                            </ol>
                           
                              <ol className={this.state.infoDivactive ? 'status-list': 'status-list_toggled'} >
                                  <li className="update_text"> Updates</li>
                                  {renderNoticeInfo}
                                  <div className={InfoExists?"updateInfoDivShort":"updateInfoDiv"} >
                                  {
                                    renderUpdateInfo
                                  }
                                  
                                  </div>
                                
                              </ol>
                            
                        </div>

                </div>
                </aside>
            </div><footer className="footer" id="footer">Bonnier Pharma Insights AB ©</footer></div>
            <div style={{width:'100vw'}}>
               {modalBodyHelp} 
            </div>
            <div style={{width:'100vw'}}>
               {modalBodyFormAlert} 
            </div>
            </div>
            );
    }
}

export default Dashboard;