import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {API_URL as API_URL} from '../constants'
let  suggestionArray = [];



export default class StrengthInput extends Component {
    constructor(props){
        super(props);
        this.state={
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
            suggestions: [],
            
        }
        this.onChange.bind(this);
        this.onKeyDown.bind(this);
        this.handleClick.bind(this);
        this.onClick.bind(this);
       
    }
    
    onKeyDown = e => {
      
        const { activeSuggestion, filteredSuggestions } = this.state;
        
        if (e.keyCode === 13) {
            this.setState({
              activeSuggestion: 0,
              showSuggestions: false,
              userInput: filteredSuggestions[activeSuggestion]
            });
            e.preventDefault();
          }else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
              return;
            }
      
            this.setState({ activeSuggestion: activeSuggestion - 1 });
          }else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
              return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
          }else if (e.keyCode === 27) {
            this.setState({
              showSuggestions: false,
              userInput: ""
            });
          }
        
      };
      onClick = e => {
        e.preventDefault();
        // Update the user input and reset the rest of the state
        this.setState({
          activeSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          userInput: e.currentTarget.innerText
        });
        return false;
      };
    onChange=(e)=>{
        const userInput = e.currentTarget.value;
       
        this.setState({
            userInput
        });
      
       
       let url = `${API_URL}size?size=${userInput}`;
        if(userInput.length){
          axios.post(url).then(response => {
            let obj = []; 
            for (const [index, value] of response.data.data.entries()) { 
              //console.log(value.value)
                          
              if(obj.includes(value.size) === false){
                obj.push(value.size)
              }
            }
            suggestionArray = [];
            suggestionArray =  [...obj];              
          })          
         }



         
        
        
            this.setState({
            activeSuggestion: 0,
            filteredSuggestions:suggestionArray,
            showSuggestions: true,          
          });
  
        //  }
        //  console.log(products)
        
    }
   
    componentWillUnmount(){
      console.log("tested")
      document.removeEventListener('mousedown', this.handleClick, false);
    }
    handleClick=(e)=>{
      if(this.node.contains(e.target)){
        this.setState({showSuggestions:true})
        return
      }
      this.setState({showSuggestions:false})
    }
   
    render() {
        const  
        {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
        } = this.state;

        let suggestionsListComponent;
        
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
              suggestionsListComponent = (
                <ul className="suggestions">
                  {filteredSuggestions.map((suggestion, index) => {
                    let className;
      
                    // Flag the active suggestion with a class
                    if (index === activeSuggestion) {
                      className = "suggestion-active";
                    }
      
                    return (
                      <li
                        className={className}
                        key={index}
                        onClick={this.onClick}
                      >
                        {suggestion}
                      </li>
                    );
                  })}
                </ul>
              );
            } else {
              suggestionsListComponent = (
                <ul className="suggestions" >
                    <li> Select...</li>
                </ul>
              );
            }
          }
        console.log(this.state.filteredSuggestions)
        return (
            <div  ref={node=>this.node = node}>
                <Fragment>
                    <input className="inputs"
                    id="sizeInput"
                    placeholder = "Select.."
                    type="text"
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    value={this.state.userInput}
                    />
                    {suggestionsListComponent}
                </Fragment>
                
            </div>
        );
    }
}

