import React, { Component } from 'react';
import { Modal, ModalHeader,ModalBody, ModalFooter,Badge} from 'reactstrap';
import statImage from '../images/stats.png';
import searchingImage from '../images/searching.png'
import tabsImage from '../images/tabs.png'
import checkBox from '../images/check_box.png'
import sorting from '../images/sorting.png'
import exporting  from '../images/export.png'
import icon from '../images/i-con.png'
import contractInfo from '../images/contract_info.png'
import lowestPrice from '../images/lowest_price.png'
import contractPrices from '../images/contract_prices.png'
import contractAlteration from '../images/contract_alternation.png'
export default class HelpModal extends Component {
    render() {
        return (
          
                <ModalBody className="modalBody">
                      <div className="help_content">
                        
                        <div style={{width:"100%"}}>
                           <p> <span className="brand">Monitend</span> contains all regional hospital contracts since 2014 as well as sales data on the hospital market.</p>
                        </div>                        
                        <div style={{width:"100%"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge color="info">Searching</Badge></h5>
                                <p>
                                    Enter one or several criteria into the relevant input fields to the left in your window. A drop down menu with possible options will appear after the third character when you start typing. (As seen on the image below). After selecting parameters press “Search”. 
                                </p>
                                <p>
                                <span className="brand">The drop down menu</span><br/>
                                     If the “Valid contracts only” box is ticked you will only see the contracts that are currently valid.

                                </p>
                                
                            </div> 
                            
                            
                            <div style={{float: "let", display:"inline", width:"50%"}}>
                            <img  style={{display:"inline-block",height:"420px",width:"20%"}}src={searchingImage} alt="monitend search form" /> 
                            </div>
                        </div>
                        
                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge color="info">Tabs</Badge></h5>
                                <img  style={{float: "left", display:"inline", width:"40%"}} src={tabsImage} alt="monitend tabs" />
                               
                                <p style={{float: "left", width:"100%", fontStyle:"italic"}}> The tabs show different aspects of the data. </p>
                               
                                <p style={{float: "left", width:"100%"}}>
                                <span className="brand_italic">Products with contracts</span> Products with contracts show all valid contracts.To see historical contracts, untick the box”valid contracts only”. (Shown on the picture below.)<br/>
                                <span className="brand_italic">Products</span>  tab show all products available on the market within the stated criteria.<br/> 
                                The tabs <span className="brand_italic">Sales units</span>  (showing volume) and <span className="brand_italic">Sales Value</span> show sales per NAN and region if ‘Region-wise sales data’ is chosen in the search field (shown in the picture below). Rows that are in darker blue color indicates that a product is within a contract in that specific region. More information is shown about the contract if you let the mouse hover over the row. To view sales nationally, simply untick <br/>
                                <img  style={{float: "left", display:"inline"}}src={checkBox} alt="monitend search form" /> 

                                </p>
                            </div>
                            <p style={{fontStyle:"italic"}}>Tick or un-tick the boxes to get the data you are looking for.</p>
                        </div>

                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge color="info">Sorting</Badge></h5>
                                
                               
                                <p style={{float: "left", width:"100%"}}>
                                    By default, search results are sorted according to a logical order.You can sort to an ascending order by clicking on its corresponding header. Clicking a second time will reverse the order. Clicking a third time will reset the order.                              
                                </p>
                                <img  style={{float: "left", display:"inline", width:"70%"}} src={sorting} alt="sorting" />
                            </div>
                            <p style={{fontStyle:"italic"}}>Sorting on Size in Ascending order.</p>
                        </div>

                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge color="info">Exporting</Badge></h5>
                                
                                <img  style={{float: "left", display:"inline", width:"320px", height:"60px"}} src={exporting} alt="exporting" />
                                <p style={{float: "left", width:"100%"}}>
                                    All search results can be exported in Excel. You find the export-button on the top right hand side of the page.
                                </p>
                            </div>
                            {/* <p style={{fontStyle:"italic"}}>Sorting on Size in Ascending order.</p> */}
                        </div>
                        
                        <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}><h5><Badge color="info">Statistics info box</Badge></h5>
                                <img  style={{float: "left", display:"inline", width:"240px", height:"350px"}} src={icon} alt="i-con" />                               
                                
                            </div>
                            <p style={{fontStyle:"italic"}}>The i-con</p>
                            <p style={{float: "left", width:"100%"}}>
                                    Click the i-icon on the right hand side of the chart to display the info box. 
                            </p>
                            <p style={{float: "left", width:"100%"}}>
                                Here you can see statistics of the contracts that are associated with the current search. Clicking on a number will add a filter based on the contract validity criteria selected. The search will then be renewed.
                            </p>
                            
                            
                        </div>
                    <div style={{width:"100%" ,float:"left"}}>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}>
                                <img  style={{float: "left", display:"inline", width:"300px"}} src={contractInfo} alt="contract info" />     
                                
                            </div>
                            <p style={{fontStyle:"italic"}}>The info box</p>

                            <p className="i-text">
                                <span>Valid contracts.</span> All contracts that are valid today. A contract is regarded as the combination of one company and one region.
                            </p>

                            <p className="i-text">
                                <span>Contracted products: expiring.</span> All products within contracts that will expire within a certain amount of time.
                            </p>

                            <p className="i-text">
                                <span>Contracted products: expired.</span> All products within contracts that are no longer valid.
                            </p>

                            <p className="i-text">
                                <span>Upcoming products contracted.</span> All products within contracts with a start date in the future.
                            </p>

                            <p className="i-text">
                                <span>Total number of contracts.</span> All contracts – with no validity filter in effect.
                            </p>

                    </div>
                    <div>
                        <div style={{float: "left", display:"inline-block",width:"100%"}}>
                            <h5><Badge color="info">Comparison of prices</Badge></h5>
                                     
                        </div>
                        
                        <div style={{width:"100%" ,float:"left"}}>
                            <p>You can view a group of contracts and their prices in a chart view by clicking the highlighted prices in any of the lowest price columns.</p>
                            <p>There are two different charts available:</p>
                            <p className="i-text">
                                <span>Lowest price Product:</span> showing all contracts belonging to a product group that shares the same substance, strength, size and dosage form. 
                            </p>
                            <p className="i-text">
                                <span>Lowest price NAN (Nordic Article Number) :</span> showing lowest price of all contracts belonging to a particular article. 
                            </p>



                            <div style={{float: "left", display:"inline-block",width:"100%"}}>
                                <img  style={{float: "left", display:"inline", width:"500px"}} src={lowestPrice} alt="lowest price product" />    
                                
                            </div>
                            <p style={{fontStyle:"italic"}}>
                                Lowest price Product
                            </p>

                            <p className="i-text">
                                To view the chart, click on the highlighted price in any of the lowest price columns (shown above). You will then get a comprehensive view of the price per region as shown on the picture below. 
                            </p>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}>
                                <img  style={{float: "left", display:"inline", width:"1000px"}} src={contractPrices} alt="contract price" />    
                                
                            </div>
                            <p style={{fontStyle:"italic"}}>
                                Contract prices chart
                            </p>
                            <p>
                                Some prices are confidential, they will then be displayed as ”n/a” but they are still displayed in order to give the information that there is an ongoing contract. 
                                A <span style={{fontWeight:"bold"}}>bold</span> contract price indicates contract alterations. Show alterations by clicking the price. 

                            </p>
                            <div style={{float: "left", display:"inline-block",width:"100%"}}>
                                <img  style={{float: "left", display:"inline", width:"920px"}} src={contractAlteration} alt="contract price" />    
                                
                            </div>
                            <p style={{fontStyle:"italic"}}>
                                 Example of what contract alterations can look like. 
                            </p>

                        </div>
                    </div>
                    <div style={{width:"100%" ,float:"left"}}>
                        <div style={{float: "left", display:"inline-block",width:"100%"}}>
                            <h5><Badge color="info">Updates and the regions</Badge></h5>
                                     
                        </div>
                        
                        <p style={{float: "left", width:"100%"}}>
                        Some regions (SLL (Stockholm & Gotland), Norrlandstingen (Jämtland, Norrbotten, Västerbotten, Västernorrland), Kronoberg and Skåne publish the information on the web and in those cases we have a script download the information daily. We regularly perform a manual double check to ensure that the file is updated. 
                        </p>
                        <p style={{float: "left", width:"100%"}}>
                        We have an ongoing dialogue with the regions that do not publish the information on the web. We also keep an eye on when agreements expire so that we can ask the regions well in advance on new ones are in progress and on which agreements have been extended.
                        </p>
                    </div>

                    <div style={{width:"100%" ,float:"left"}}>
                        <div style={{float: "left", display:"inline-block",width:"100%"}}>
                            <h5><Badge color="info">Customer Support</Badge></h5>
                        </div>
                        
                        <p className="n-text">
                            If you have any other questions please contact <span>support@bonnierpharmainsights.se</span>.
                        </p>

                       
                    </div>

                        {/* <img  style={{float: "left", display:"inline", width:"300px"}} src={contractInfo} alt="sorting" /> 
                                <p style={{fontStyle:"italic"}}>The info box</p> */}
                       
                        
                        
                    </div>
                  </ModalBody>
          
        );
    }
}
