/* eslint-disable no-unused-expressions */
import React, { Component,ReactDOM } from 'react';
import {API_URL} from './constants'
import axios from 'axios';
import spinning_pill from '../images/pill.png'
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { Modal, ModalHeader,ModalBody, ModalFooter,Badge} from 'reactstrap';


const isIE = /*@cc_on!@*/false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;


// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';


let div1Height = '82vh';
let div2Height = '85vh'; // chrome, safari, edge    

const arrowLeft = <FontAwesomeIcon icon={ faAngleLeft} />
const arrowRight = <FontAwesomeIcon icon={ faAngleRight} />
const arrowDoubleLeft = <FontAwesomeIcon icon={ faAngleDoubleLeft} />
const arrowDoubleRight = <FontAwesomeIcon icon={ faAngleDoubleRight} />
const formatConfig = {
  //style: "currency",
  //currency: "SEK", // kr is showing instead of SEK that is why commented out
  minimumFractionDigits: 2 
};

let currentPage = 1;
let initialNumber  = 1;
let lastNumber = 0;
let numberofRows=0;
let singleRowHeight = 31; //height of table rows are 31px
let previousSort = ""
let currentRowNum = "";
let lastRowNum  = "";
//let dataFields = ['nan', 'company', 'name', 'se', 'strength', 'size', 'form'];
let columns = [
    {
      label: 'ATC',
      field: 'code',
      sort: 'asc',
    
    },
    {
      label: 'Substance',
      field: 'se',
      sort: 'asc',
    
    },
  
    
    {
      label: 'Company',
      field: 'company',
      sort: 'asc',
     
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
     
    },
    
    {
      label: 'Strength',
      field: 'strength',
      sort: 'asc',
     
    },
    {
      label: 'Size',
      field: 'size',
      sort: 'asc',
     
    },
    {
      label: 'Form',
      field: 'form',
      sort: 'asc',
     
    },
    {
      label: 'NAN',
      field: 'nan',
      sort: 'asc',
     
    },
    
    
  ];
  let regionToAdd =[{
    label: 'Region',
    field: 'region',
    sort: 'asc',
   
  }];
  let rows = [
  ];
  let matField = [
    {
      label: 'MAT',
      field: 'mat'     
    }
  ];

  let sortable = [
    'nan', 'company', 'name', 'se', 'strength','size','form'
  ]

let sameSearch = 0;  
let searchTerm = {};
let oldSearchTerm = {};
//let totalRows = 0;
export default class SalesVolume extends Component {
    constructor(prop){
        super(prop);
        
        this.table1 = React.createRef();
        this.state = {
            data: [],
            contractData:[],
            totalRows: 0,
            searchTerm:this.props.searchTerm,
            loading:true,
            searchTermLoaded:0,
            totalRowsFound :0,
            actualRows:0,
            clickedSearch : 0,
            orderBy:"",
            orderDirection: "asc",
            totalPage:0,
            currentPage:1,
            onlyValid:true,
            regionWise:true,
            dataColumn:'ims_sales_volume',
            nextOrPrevClicked:false,
            numberofRows:this.props.numberofRows,
            secondWidth:'',
            place: "top",
            type: "light",
            effect: "float",
           
            tableHeight:"",
            numberofRows:0,
            formAlert:false,
            emptyModal:false,
            modalResponse:""
            
              
        
        };
        searchTerm = this.props.searchTerm
        let data = this.props.searchTerm
        
        this.doNothing.bind(this);
        this.checkIfSameSearchString.bind(this);
        this.addClasses.bind(this);
        this.getRowId.bind(this);
        this.mouseOut.bind(this);
        this.removeClasses.bind(this);
        this.calulateWidth.bind(this);
        this.sortOnFly.bind(this);
        this.handleTable.bind(this);
        this.toogleEmptyModal.bind(this);
       
    }

    toogleEmptyModal=()=>{
      this.setState({
        emptyModal:!this.state.emptyModal,
        
      })
    }

    handleTable=()=>{
      let div1 = document.getElementById('parent_div_1');
        if(div1){
          var mainWindow = document.getElementById('dashboardWindow').offsetWidth;
          let containerHeight = mainWindow.offsetHeight;
          let tabHeight = document.getElementById("walkthrough_salesval").offsetHeight;
          let footerHeight = document.getElementById("footer").offsetHeight;
          
          if(isFirefox ){        
            //let div2Height = (containerHeight+10)-(tabHeight+(footerHeight*2)+5)
            div1Height = (containerHeight)-(tabHeight+(footerHeight*2)+10)
            div2Height = div1Height+17;
            
          }else{
            div1Height = (containerHeight)-(tabHeight+(footerHeight*2))
            div2Height = div1Height+8; // chrome, safari, edge       
          }
          var div2Elem = document.getElementById('parent_div_2');
          var search_pannel = document.getElementById('search_pannel').offsetWidth;
          var widthDiv1 = document.getElementById('parent_div_1').offsetWidth;
          var widthDiv2 = "";
          widthDiv2 = (mainWindow-(widthDiv1+search_pannel+3));
          //widthDiv2 = 100-(search_pannel*2+ (100/mainWindow)*widthDiv1)
          document.getElementById('parent_div_2').style.maxWidth = widthDiv2+"px";
          document.getElementById('parent_div_1').style.maxHeight = div1Height+"px";
          document.getElementById('parent_div_2').style.maxHeight = div2Height+"px";
          
          
        }
    }
    calulateWidth=(e)=>{
      
      var mainWindow = document.getElementById('dashboardWindow').offsetWidth;
      var search_pannel = document.getElementById('search_pannel').offsetWidth;
      var widthDiv1 = document.getElementById('parent_div_1').offsetWidth;
      var widthDiv2 = "";
      widthDiv2 = (mainWindow-(widthDiv1+search_pannel));
      this.setState({
        secondWidth:widthDiv2+'px'
      })
      e.preventDefault();


    }
    mouseOut=(e)=>{
      //console.log("out")
      this.removeClasses()
    }
    removeClasses=()=>{
      var elems = document.querySelectorAll(".sample_class");
      [].forEach.call(elems, function(el) {
          el.classList.remove("sample_class");
      });
    }

    addClasses=(currentRow, currentTable)=>{    
      let table = (currentTable==="table1")? "table2":"table1"
      let tableId = (currentTable==="table1")? "parent_div_1":"parent_div_2"
      // let tableWhite = document.getElementById(tableId);
      // tableWhite.classList.add("tableWhite");  
      var elems = document.querySelectorAll(".sample_class");
        [].forEach.call(elems, function(el) {
            el.classList.remove("sample_class");
        });
        let item = document.getElementById(table+"_"+currentRow);
        // if(item.classList.contains('sales_in_contract')){
        //   item.classList.remove("sales_in_contract");
        // }
        item.classList.add("sample_class");

      
    }

    getRowId=(e)=>{
      let target = e.currentTarget.id;
      let rowNumArr = target.split("_");
      let currentTable = rowNumArr[0];
      let rowNum = rowNumArr[1];
      currentRowNum = rowNum
      //console.log(currentRowNum, currentTable)
      this.addClasses(currentRowNum, currentTable)
    }

    doNothing=()=>{
      return false;
    }

    sort = (field, reverse, datType, primer) => {
      let key = "";
      if(datType === 'numeric'){
        key = primer ?
        function(x) {
          let val =primer(x[field])
          if (isNaN(val)) {
            return 0;
          }
          return val;
        } :
        function(x) {
          return x[field]
        };
      }else{
        key = primer ?
        function(x) {          
          return primer(x[field]);
        } :
        function(x) {
          return x[field]
        };
      }
        
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      }
    }

    // sort(key, order='desc'){
    //   return function(a, b) {
    //     if(!a.hasOwnProperty(key) || 
    //        !b.hasOwnProperty(key)) {
    //       return 0; 
    //     }
    //     const varA = (typeof a[key] === 'string') ? 
    //       a[key].toUpperCase() : a[key];
    //     const varB = (typeof b[key] === 'string') ? 
    //       b[key].toUpperCase() : b[key];
          
    //     let comparison = 0;
    //     if (varA > varB) {
    //       comparison = 1;
    //     } else if (varA < varB) {
    //       comparison = -1;
    //     }
    //     return (
    //       (order === 'desc') ? 
    //       (comparison * -1) : comparison
    //     );
    //   };

    // }
    sortOnFly=(e)=> {
      let orderBy = "";
      let initialSort = previousSort;
      
      if(e.currentTarget.id !== ''){
        orderBy = e.currentTarget.id;
      }
      
      previousSort = orderBy;
      
      let orderDirection;
      if(initialSort !== orderBy && this.state.orderDirection !==''){
        if(initialSort !==''){
          let initialDirection  = this.state.orderDirection;
          let element = document.getElementById(initialSort+'_'+initialDirection);
          element.classList.add("hidden");

        }
        orderDirection = 'asc'; 
      }else{
        if(this.state.orderDirection === ''){
          orderDirection = 'asc'        
        }else if(this.state.orderDirection === 'asc'){
          orderDirection = 'desc'        
        }else if(this.state.orderDirection === 'desc'){
          orderDirection = ''                
        }

      }
      this.setState({
        orderBy:orderBy,
        orderDirection:orderDirection          
      })
      
      let searchT =  localStorage.getItem('monitendSearchTerm')
      if(searchT !== "{}" && searchT !== 'undefined'&& searchT !== null  ){
        let termForDownload = JSON.parse(searchT);
        termForDownload.salesVolOrder = orderBy;
        termForDownload.salesVolOrderDir = orderDirection;
        let seacrObject = JSON.stringify(termForDownload);
        localStorage.setItem('monitendSearchTerm', seacrObject);
        
      }

      if(orderDirection !== ""){
        const { data } = this.state;               
        //data.sort(this.sort(orderBy, orderDirection))    
        //this.setState({ data })
        let numericField = ["nan", 'strength','size'];
        
        if(numericField.includes(orderBy)){
          let reverse  = (orderDirection==='desc')? true:false;
          data.sort(this.sort(orderBy, reverse,'numeric', parseFloat))
        }else{
          let reverse  = (orderDirection==='desc')? true:false;
          data.sort(this.sort(orderBy, reverse,'string', (a) =>  a))
        }
      }else{
         let seacrhObj =  this.state.searchTerm
        let seacrObject = JSON.stringify(seacrhObj);
          
          axios.post(`${API_URL}get_sales`, {
            seacrhObj: seacrObject
          }).then(response => {
            if(response.status === 200 && response.data.data.length >0){
              let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
              //prodData = response.data.data;
              this.setState({
                  data: response.data.data,
                  totalRowsFound:response.data.rowsFound[0].total_row,
                  actualRows:response.data.actual_sales_data[0].total_row,
                  totalPage : totalPage,
                  
              })
          }else{
              this.setState({
                  data: [],
                  loading: false,
                  totalRowsFound: response.data.rowsFound[0].total_row,
                  actualRows:response.data.actual_sales_data[0].total_row,
              })
            } 
          }).catch( (err) => {
            document.location.href="/";
          })
      }
      

      var tableId = e.currentTarget.hasAttribute("scope") ?"sales_table_1":"parent_div_2";
      // let sameSearch = false;
      // if(initialSort === orderBy){
      //   sameSearch =
      // }

      var rows = document.getElementById(tableId).getElementsByTagName("tbody")[0].getElementsByTagName("tr").length;
      let totalEntries = rows;
      if(rows<numberofRows){
        totalEntries = rows;
      }
      if(rows<numberofRows){
        totalEntries = rows;
      }
      if(initialSort !== ''){
        let prevAscElement = document.getElementById(initialSort+'_asc');
        let prevDescElement = document.getElementById(initialSort+'_desc');

        if(orderDirection === 'asc'){
          prevDescElement.classList.add("hidden");          
        }else if(orderDirection === 'desc'){
          prevAscElement.classList.add("hidden");          
        }

        let removeHeaderHighlight = document.getElementById(initialSort);
        removeHeaderHighlight.classList.remove("contract-price");
        for(let i=0; i<totalEntries; i++){
          let dataCell = document.getElementById(i+'_'+initialSort);
          dataCell.classList.remove("contract-price");
        }
       
        
      }
      let element = document.getElementById(orderBy+'_'+orderDirection);
      let headerToHighlight = document.getElementById(orderBy);
      headerToHighlight.classList.add("contract-price");
      for(let i=0; i<totalEntries; i++){
        let dataCell = document.getElementById(i+'_'+orderBy);
        dataCell.classList.add("contract-price");
      }
      
     
    if(orderDirection !==""){
      element.classList.remove("hidden");
    }else{
      // this.setState({
      //   data:this.state.initialData
      // })
      
      let previousDirection = this.state.orderDirection;
      let element = document.getElementById(orderBy+'_'+previousDirection);
      element.classList.add("hidden");
      let removeHeaderHighlight = document.getElementById(orderBy);
        removeHeaderHighlight.classList.remove("contract-price");
        for(let i=0; i<totalEntries; i++){
          let dataCell = document.getElementById(i+'_'+orderBy);
          dataCell.classList.remove("contract-price");
        }
        orderBy = "";
        this.setState({ 
          orderDirection:"",
          orderBy : "" 
        })
        
    }
      
  }

    
    componentDidMount(){
      
      var vh=document.getElementById('vh');
      let screenHeight = vh.clientHeight;
      let tableHeight = 85; // 86 vh: 100vh total screen; 6vh navigation, 2 vh footer, 4 vh tabs height, 1 view height for spacing and 1 vh for safety
      let tableHeightPx = (screenHeight/100)*tableHeight;
      
      
      let singleRowHeight = 32;
      let numberofRows = Math.ceil(tableHeightPx/singleRowHeight)
      this.setState({
        tableHeight:tableHeightPx,
        numberofRows:numberofRows
      })

      const search = localStorage.getItem('monitendSearchTerm');
      let searchTerm = JSON.parse(search)
      if(Object.keys(searchTerm).length > 0){
        searchTerm.currentPage = this.state.currentPage;
        searchTerm.dataColumn = 'ims_sales_volume';
        searchTerm.contractInfo = true; 
        let regionWise = searchTerm.regionWise
        
        let seacrObject = JSON.stringify(searchTerm);
        
        this.setState({
            searchTerm:searchTerm,
            searchTermLoaded : 1,
            clickedSearch : 1,
            regionWise: regionWise
        })
        //first getting the contract info to show which sales data are in contract 
        //console.log(`${API_URL}search?seacrhObj=${seacrObject}`)
        axios.post(`${API_URL}search`, {
          seacrhObj: seacrObject
        }).then(response => {
          this.setState({
            contractData:response.data.data,
          })             
        }).catch( (err) => {
          document.location.href="/";
        })

        //console.log(`${API_URL}get_sales?seacrhObj=${seacrObject}`)
        axios.post(`${API_URL}get_sales`, {
          seacrhObj: seacrObject
        }).then(response => {
              if(response.status === 200 && response.data.data.length >0){
                  let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
                  //prodData = response.data.data;
                  this.setState({
                      data: response.data.data,
                      totalRowsFound:response.data.rowsFound[0].total_row,
                      actualRows:response.data.actual_sales_data[0].total_row,
                      totalPage : totalPage,
                  })
              }else if(response.status === 201){
                this.setState({
                  formAlert:true,
                  loading:false,
                  emptyModal:!this.state.emptyModal,
                  modalResponse:response.data.EmptySearch
                 
                })
              }else{
                  this.setState({
                      data: [],
                      loading: false,
                      totalRowsFound: response.data.rowsFound[0].total_row,
                      actualRows:response.data.actual_sales_data[0].total_row,
                  })
              }                 
          }).catch( (err) => {
            document.location.href="/";
          })
      } 
      
    }
    checkIfSameSearchString(str1, str2){
      let sameString = true;
      let keys = Object.keys(str2)
      for (let key of keys) { 
        if(key === 'salesVolOrder'){
          continue;
        }
        if(key === 'salesVolOrderDir'){
          continue;
        }
        let t1 = !(key in str1)? "":str1[key];
        let t2 = !(key in str2)? "":str2[key];
        if( JSON.stringify(t1).trim() !== JSON.stringify(t2).trim()){
          return false;
        }        
      }
      return sameString;
      

    }
    
    componentDidUpdate(prevProps, prevState) {
      
      searchTerm = this.props.searchTerm;
      oldSearchTerm = prevProps.searchTerm;
      numberofRows = this.props.numberofRows;
      if(searchTerm !== null){
        sameSearch = this.checkIfSameSearchString(oldSearchTerm, searchTerm);
      if(!sameSearch){
        let searchTerm = this.props.searchTerm;
        searchTerm.dataColumn = 'ims_sales_volume';
        searchTerm.contractInfo = true;    
            if(Object.keys(searchTerm).length > 0){
                let regionWise = searchTerm.regionWise
                let seacrObject = JSON.stringify(searchTerm);
                this.setState({
                    searchTerm:searchTerm,
                    searchTermLoaded : 1,
                    clickedSearch:1,
                    regionWise:regionWise, 
                    data:[],
                    numberofRows:numberofRows,
                    onlyValid: searchTerm.onlyValid   ,
                    currentPage: searchTerm.currentPage,
                    loading:true                     
                })
               //console.log(`${API_URL}get_sales?seacrhObj=${seacrObject}`)
                axios.post(`${API_URL}search`, {
                  seacrhObj: seacrObject
                }).then(response => {
                  this.setState({
                    contractData:response.data.data,
                  })             
                }).catch( (err) => {
                  document.location.href="/";
                })
                
                axios.post(`${API_URL}get_sales`, {
                  seacrhObj: seacrObject
                }).then(response => {
                    if(response.status === 200 && response.data.data.length >0){
                      
                        //prodData = response.data.data;
                        let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
                        this.setState({
                            data: response.data.data,
                            totalRowsFound:response.data.rowsFound[0].total_row,
                            actualRows:response.data.actual_sales_data[0].total_row,
                            totalPage: totalPage,
                            loading:false
                        })
                    }else if(response.status === 201){
                      
                      this.setState({
                        formAlert:true,
                        loading:false,
                        emptyModal:!this.state.emptyModal,
                        modalResponse:response.data.EmptySearch
                        
                      })
                    }else{
                        this.setState({
                            data: [],
                            loading: false,
                            totalRowsFound: response.data.rowsFound[0].total_row,
                            actualRows:response.data.actual_sales_data[0].total_row,
                        })
                    }                     
                }).catch( (err) => {
                  document.location.href="/";
                })
            }
        }
       
      }

     
     
     
        
    }
    render() {
      const { place, type, effect,tableHeight,numberofRows, actualRows,totalRowsFound} = this.state;

      let renderProductTable;
      let renderFixedTableBody = "";
      let renderTableHeader ="";
      let showSpinningPill = true;
      let renderTableBody;
      let renderPagination;
      let divOneSpHeight = "";
    
      let sortable = [
        'region','code','se', 'company', 'name',  'strength','size'
      ]
      if(!this.state.regionWise){
        sortable = [
          'code','se', 'company', 'name',  'strength','size'
        ]
      }
      let scrollAndSortAble = ['form','nan']
      let dataFields = ['region','code','se', 'company', 'name',  'strength','size','form','nan'];
      if(!this.state.regionWise){
        dataFields = [ 'code','se', 'company', 'name',  'strength','size','form','nan'];
      }
      
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const d = new Date();
      const thisYear = d.getFullYear();
      const lastYear = (d.getFullYear() - 1);
      let lastCol =  d.getMonth()-1;
      let thisYearsMonths = [];
      let lastYearsMonths = [];
      let yearHeader = [];
      
      let thisYMonthsRow = [];
      let lastMonthRow = [];
      let dataRow=[];
      let k= 0;

     
      for(let i=0; i<=lastCol; i++ ) {
        let label = " "+(monthNames[i])+"' "+thisYear; 
        thisYearsMonths.push(
          {
            label: label,
            field: monthNames[i]
           
          }
        
        );
        thisYMonthsRow.push(
          monthNames[i]
        )
      }

      for(let i=lastCol+1; i<=11; i++ ) {
        let label = " "+(monthNames[i])+"' "+lastYear; 
        lastYearsMonths.push(
          {
            label: label,
            field: monthNames[i]            
           
          }
        );
        lastMonthRow.push(
          monthNames[i]
        )
      }
      yearHeader = [...lastYearsMonths, ...thisYearsMonths]
      dataRow = [...dataFields,...lastMonthRow, ...thisYMonthsRow, ...['mat'] ]
     
      
      let tableHeader = [...columns,...yearHeader,...matField]

      if(this.state.regionWise){
        tableHeader = [...regionToAdd,...columns,...yearHeader,...matField]       
      }
     let y = ""
      let renderFixedHeader;
      initialNumber  = (currentPage-1)*numberofRows+1;
      lastNumber = currentPage*numberofRows;
      if(this.state.totalRowsFound > 0 ){
        renderPagination=(
          <div className="pagination_style">
            <div className="product_counter">{this.state.totalRowsFound}{(this.state.totalRowsFound>1?" records":" record")}  found</div>
            {/* <div className="pagination_container">
                <span className={(this.state.currentPage ===1 ) ? 'disabled_link': 'regular_link'}  onClick={this.goFirstPage} >{arrowDoubleLeft}</span> &nbsp; &nbsp;
                <span className={(this.state.currentPage ===1 ) ? 'disabled_link': 'regular_link'}  onClick={this.goPrevPage} >{arrowLeft}</span> &nbsp;
                    {this.state.currentPage} / {this.state.totalPage} &nbsp;
                <span className={(this.state.currentPage === this.state.totalPage ) ? 'disabled_link': 'regular_link'}  onClick={this.goNextPage} >{arrowRight}</span> &nbsp; &nbsp;
                <span className={(this.state.currentPage === this.state.totalPage ) ? 'disabled_link': 'regular_link'}  onClick={this.goLastPage} >{arrowDoubleRight}</span>
            </div> */}
          </div>
        )
      }
      
      renderTableHeader = (
        <tr>
           {tableHeader.map((suggestion, index) => {
             if(!sortable.includes(suggestion.field)){
               
              if(scrollAndSortAble.includes(suggestion.field)){
                let filed = suggestion.field
              return (
                 <th key={index} id={suggestion.field}
                className = "clickable"
                onClick = {this.sortOnFly}
              >
                    {suggestion.label} &nbsp;
                    {scrollAndSortAble.includes(suggestion.field )? <span id={suggestion.field+'_asc'} className="asc hidden">▲</span>: <span>&nbsp;</span> }
                  {scrollAndSortAble.includes(suggestion.field )?  <span id={suggestion.field+'_desc'} className="desc hidden">▼</span>:<span>&nbsp;</span>}
                </th>

              )
              }else{
                return (
                     <th key={index} id={suggestion.field}
                       className ="not_clickable">
                       {suggestion.label} &nbsp;
                     </th>
                    );
              }
            //  
             }
           })}
           
         </tr>
       );
       renderFixedHeader = (
        <tr>
          {tableHeader.map((suggestion, index) => {
            if(sortable.includes(suggestion.field)){
              let filed = suggestion.field
              return (
                 <th key={index} id={suggestion.field}
                className = {sortable.includes(filed)? "clickable": "not_clickable"} 
                onClick = {sortable.includes(filed)? this.sortOnFly:this.doNothing}
              >
                    {suggestion.label} &nbsp;
                    {sortable.includes(suggestion.field )? <span id={suggestion.field+'_asc'} className="asc hidden">▲</span>: <span>&nbsp;</span> }
                  {sortable.includes(suggestion.field )?  <span id={suggestion.field+'_desc'} className="desc hidden">▼</span>:<span>&nbsp;</span>}
                </th>

              )

            }
          })}

        </tr>
      )
      y=1;
      renderFixedTableBody = (
      <tbody id="fixedTableBody" onMouseOut={this.mouseOut} style={{minWidth:'100%'}} >
          
          {this.state.data.map((row, index) => {
            let singleRow = row;
            let leftAlign=['company', 'name','se']
            let sales_in_contract_class = "";
            if(this.state.regionWise){
              sales_in_contract_class = 'sales_in_contract';
            }else{
              sales_in_contract_class = '';
            }
            let showTip = false;
            let contractFrom  =  "";
            let contractTo = "";
            let maxExtension = "";
            let price = "";
            let contractInfoExists = false;
            if(Array.isArray(this.state.contractData['unique_contract']) && this.state.contractData['unique_contract'].length){
              contractInfoExists = true
              
                   
              if(contractInfoExists && this.state.contractData['unique_contract'].includes(row.unique_contract)){
              
              contractFrom  =  this.state.contractData['contract_info'][row.unique_contract].contract_from;
              contractTo = this.state.contractData['contract_info'][row.unique_contract].contract_to;
              let extension = this.state.contractData['contract_info'][row.unique_contract].max_extension_to;
              maxExtension = (extension === null || extension === "")? "n/a" : extension;
              price = new Intl.NumberFormat('sv-SE',formatConfig).format(this.state.contractData.contract_info[row.unique_contract].price);
              let today = new Date();
                let contractDate = new Date(contractTo);
                if((today.getTime() === contractDate.getTime()) || contractDate.getTime()>today.getTime()){
                  showTip = true;
                }
              }
            }
            
            return ( //
              
              //   
                <tr key={index} id={"table1_"+index} onMouseOver={this.getRowId} className={contractInfoExists && this.state.contractData['unique_contract'].includes(row.unique_contract) && showTip? sales_in_contract_class+ ` sic_unique_${y++}` :''}>
                  {sortable.map((dataIndex, value) => {
                   let addedString = ""
                   var regionText = ""
                  
                     
                   if(dataIndex === 'region'){
                     let tool_tip_link ="";
                     
                     
                    //singleRow[dataIndex.toLowerCase()]
                    regionText = (singleRow['suborder'] !=='')? singleRow[dataIndex.toLowerCase()]+' (Coop.: '+singleRow['suborder']+')':singleRow[dataIndex.toLowerCase()]; 
                    
                      return(
                        (this.state.regionWise)?                                                                                                                                                                                                   
                        <td id={index+'_'+dataIndex} key={ k++} className={"text-left region"+index}>
                          {contractInfoExists && this.state.contractData['unique_contract'].includes(row.unique_contract) && showTip? 
                          (
                            <Tooltip
                              content={(
                                <div>
                                  <ul className="tip-list">
                                    <li>{"Contract from: "+contractFrom}</li>
                                    <li>{"Contract to: "+contractTo}</li>
                                    <li>{"Max extension to: "+maxExtension}</li>
                                    <li>{"Price: "+price+" SEK"}</li>
                                    
                                  </ul>
                                </div>
                              )}
                              direction="up"
                              tagName="div"
                              className="target"
                              tipContentClassName=""
                            >
                              <div>
                                { regionText}
                              </div>
                            </Tooltip>
                          )
                          
                          : 
                          regionText}
                         
                        </td>
                        :
                        <td id={index+'_'+dataIndex} key={ k++} className={"text-left region"+index}>
                          {regionText}
                        </td>
                      )  
                    
                    
                   }else{
                    
                    return(                                                                                                                                                                                                   
                      (this.state.regionWise)? <td id={index+'_'+dataIndex} key={ k++} className={leftAlign.includes(dataIndex)?"text-left": dataIndex==='nan'?"text-center":"text-right"}>
                        {contractInfoExists && this.state.contractData['unique_contract'].includes(row.unique_contract) && showTip? 
                        (
                          <Tooltip
                              content={(
                                <div>
                                  <ul className="tip-list">
                                    <li>{"Contract from: "+contractFrom}</li>
                                    <li>{"Contract to: "+contractTo}</li>
                                    <li>{"Max extension to: "+maxExtension}</li>
                                    <li>{"Price: "+price+" SEK"}</li>
                                    
                                  </ul>
                                </div>
                              )}
                              direction="up"
                              tagName="div"
                              className="target"
                              tipContentClassName=""
                            >
                              <div>
                                { singleRow[dataIndex.toLowerCase()]}
                              </div>
                            </Tooltip>
                        )
                        : 
                        singleRow[dataIndex.toLowerCase()]}
                        
                      </td>
                      :
                      <td id={index+'_'+dataIndex} key={ k++} className={leftAlign.includes(dataIndex)?"text-left": dataIndex==='nan'?"text-center":"text-right"}>
                      {singleRow[dataIndex.toLowerCase()]}
                      </td>
                    )
                    
                   }
                    
                  })}
                </tr>
              // </a>
              
            )
          })
        }
        </tbody>

      )

      let mainWIndow = document.getElementById('dashboardWindow');
      if(mainWIndow){
        let containerHeight = mainWIndow.offsetHeight;
        let tabHeight = document.getElementById("walkthrough_salesval").offsetHeight;
        let footerHeight = document.getElementById("footer").offsetHeight;
        
        if(isFirefox ){        
          //let div2Height = (containerHeight+10)-(tabHeight+(footerHeight*2)+5)
          div1Height = (containerHeight)-(tabHeight+(footerHeight*2)+10)
          div2Height = div1Height+17;
          
        }else{
          div1Height = (containerHeight)-(tabHeight+(footerHeight*2))
          div2Height = div1Height+8; // chrome, safari, edge       
        }

      }
      
       renderTableBody = ( 
        <tbody id="tableBody" onMouseOut={this.mouseOut}>
          {this.state.data.map((row, index) => {
            let claculatedMat = (Number(row.jan)+Number(row.feb)+Number(row.mar)+Number(row.apr)+Number(row.may)+Number(row.jun)+Number(row.jul)+Number(row.aug)+Number(row.sep)+Number(row.oct)+Number(row.nov)+Number(row.dec));
            let matValue = row.hasOwnProperty('mat')? row.mat: claculatedMat;
            let singleRow = row;
            let leftAlign=['company', 'name','se']
            let sales_in_contract_class = "";
            if(this.state.regionWise){
              sales_in_contract_class = 'sales_in_contract';
            }else{
              sales_in_contract_class = '';
            }
            let showTip = false;
            let contractFrom  =  "";
            let contractTo = "";
            let maxExtension = "";
            let price = "";
            let contractInfoExists = false;
            if(Array.isArray(this.state.contractData['unique_contract']) && this.state.contractData['unique_contract'].length){
              contractInfoExists = true
              
                   
              if(contractInfoExists && this.state.contractData['unique_contract'].includes(row.unique_contract)){
              
              contractFrom  =  this.state.contractData['contract_info'][row.unique_contract].contract_from;
              contractTo = this.state.contractData['contract_info'][row.unique_contract].contract_to;
              let extension = this.state.contractData['contract_info'][row.unique_contract].max_extension_to;
              maxExtension = (extension === null || extension === "")? "n/a" : extension;
              price = new Intl.NumberFormat('sv-SE',formatConfig).format(this.state.contractData.contract_info[row.unique_contract].price);
              let today = new Date();
                let contractDate = new Date(contractTo);
                if((today.getTime() === contractDate.getTime()) || contractDate.getTime()>today.getTime()){
                  showTip = true;
                }
              }
            }
            
            //className={this.state.regionWise && this.state.contractData['unique_contract'].includes(row.unique_contract)? sales_in_contract_class :""}
            return (
              <tr key={index} id={"table2_"+index} onMouseOver={this.getRowId} className={contractInfoExists && this.state.contractData['unique_contract'].includes(row.unique_contract) && showTip? sales_in_contract_class :""}>
                  {
                    dataRow.map((dataIndex, value) => {
                      if(!sortable.includes(dataIndex)){
                        let addedString = '';
                       
                        let currencyCol = false;
                        if(!dataFields.includes(dataIndex)){
                          currencyCol = true;
                        }
                       
                        
                        return( 
                          
                          <td id={index+'_'+dataIndex} key={ k++} className={leftAlign.includes(dataIndex)?"text-left": dataIndex==='nan'?"text-center":dataIndex==='form'?"text-left":"text-left"}>
                            { contractInfoExists && this.state.contractData['unique_contract'].includes(row.unique_contract) && showTip? 
                            <Tooltip
                            content={(
                              <div>
                                <ul className="tip-list">
                                  <li>{"Contract from: "+contractFrom}</li>
                                  <li>{"Contract to: "+contractTo}</li>
                                  <li>{"Max extension to: "+maxExtension}</li>
                                  <li>{"Price: "+price+" SEK"}</li>
                                  
                                </ul>
                              </div>
                            )}
                            direction="up"
                            tagName="div"
                            className="target"
                            tipContentClassName=""
                          >
                            <div> 
                              { (currencyCol)?
                                (
                                  (dataIndex === 'mat')? matValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")  +" "+ addedString :
                                  ( Number(singleRow[dataIndex.toLowerCase()])>0)? singleRow[dataIndex.toLowerCase()].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")  +" "+ addedString : '\u00A0'
                                ) 
                                : 
                                singleRow[dataIndex.toLowerCase()]
                              }
                            </div>
                          </Tooltip>
                              :
                              (currencyCol)?
                              (
                                (dataIndex === 'mat')? matValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")  +" "+ addedString :
                                ( Number(singleRow[dataIndex.toLowerCase()])>0)? singleRow[dataIndex.toLowerCase()].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")  +" "+ addedString : '\u00A0'
                              ) 
                              : 
                              singleRow[dataIndex.toLowerCase()]
                                
                                }
                          </td>

                        )
                      }
                    })
                  }
              </tr>
            );
          })}
        </tbody> 
      );
      
      
      

        if(this.state.data.length > 0 && this.state.clickedSearch === 1 && !this.state.nextOrPrevClicked){
            showSpinningPill = false;
            renderProductTable = (
                <ScrollSync>
                <div className="parent" >
                  
                  <table className="table_row" style={{display: "table", width: 'auto'     }}>
                    <tbody>
                      <tr>                  
                        <td id="first_td_container">
                          <ScrollSyncPane group="vertical">  
                            
                              <table id="parent_div_1" style={{maxHeight:div1Height}}  ref={this.table1} >
                                
                              <thead>
                                {renderFixedHeader}
                                </thead>      
                                {renderFixedTableBody} 
                              </table>
                            
                          </ScrollSyncPane>
                        </td>
                          <td  id="">
                          <ScrollSyncPane group="vertical">
                            
                              <table id='parent_div_2' style={{ maxWidth: this.state.secondWidth, maxHeight:div2Height}}>
                              <thead>
                                {renderTableHeader}
                                </thead>      
                                {renderTableBody} 
                              </table>
                            
                          </ScrollSyncPane>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </ScrollSync>
            )
        }else if( this.state.clickedSearch === 1 || ( this.state.clickedSearch === 1 && this.state.nextOrPrevClicked)){
            showSpinningPill = true;
            if(this.state.loading){
              renderProductTable = (
                  <div className={(showSpinningPill) ? 'spinning_pill': 'spinning_pill_hidden'}>
                    <img src={spinning_pill} alt="spinning_pill" />
                  </div>   
              )
            }else{
              if(actualRows>0){                
                renderProductTable = (
                  <div className="no_data_found">You can’t currently see sales. If you want to be able to see sales you can contact us at support@bonnierpharmainsights.se</div>
                );
              }else{renderProductTable = (
                  <div className="no_data_found">No records found</div>            
                 )
              }
            }
            
        }else if(this.state.totalRowsFound === 0 && this.state.clickedSearch === 0 ){
            showSpinningPill = false;
            renderProductTable = (
              <div className="no_data_found">Search for products</div>            
            )
        }
        let modalBodyFormAlert = "";
        if(this.state.formAlert){
          modalBodyFormAlert = (
            
            <Modal style=  {{maxWidth: '800px', width: '800px'}}  isOpen={this.state.emptyModal} toggle={this.toogleEmptyModal}>
                <ModalHeader  toggle={this.toogleEmptyModal} charCode="x" className="modal_title">Empty search form!</ModalHeader>
                <div className="form_alert"><p> {this.state.modalResponse}</p></div>
                <ModalFooter> 
                  <button onClick={this.toogleEmptyModal} type="button" className="form-button">Ok</button>                 
                </ModalFooter>
              </Modal>
          )
        }
        
        return ( 
          <div onMouseEnter={this.handleTable}>                
            <div>
                {renderPagination}
                {renderProductTable}
                
            </div>
            <div style={{width:'100vw'}}>
               {modalBodyFormAlert} 
            </div>
          </div>
        );
    }
}