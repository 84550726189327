import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
import {
  Container, Col, Form,
  FormGroup, Input,
  Button,
} from 'reactstrap';
import Recaptcha from 'react-recaptcha';
import logo from '../images/logo.png';
import Dashboard from './dashboard';
import {API_URL} from './constants'
import { EXITED } from 'react-transition-group/Transition';
import ReactGA from 'react-ga';

export const initReactGA = () =>{
  ReactGA.initialize('UA-140726016-2',{
    titleCase: false,});
}
export const loadPageView = ()=>{
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export const _BART = (w,d,t,u,n,a,m,l,s)=>{
  
  w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments);};
  a=d.createElement(t);
  m=d.getElementsByTagName(t)[0];
  a.async=1;
  a.src=u;
  m.parentNode.insertBefore(a,m);
}


var md5 = require('md5');
var sha512 = require('sha512');
let api_token = "";


class Home extends Component {
    constructor(props){
        super(props);
        
        this.state = {
          loggedInStatus : 'NOT_LOGGED_IN' ,
          email: "",
          password:"",
          user:{},
          error:false,
          api_token:"",       
          
          resetPassToken: false,
          passwordResetBool: false,
          loginInterface:1,
          validEmail:true,
          stage1:true,
          forceSignOutModal: false,
          emailSentMsg:"",
          verified: false
        }
       this.checkLoginStatus.bind(this);
       this.handelSubmit.bind(this);
       this.handelChange.bind(this);
       this.handleResetPass.bind(this);
       this.handleResetPassForm.bind(this);
       this.validateEmail.bind(this);
       this.handleResetPassSuccess.bind(this);
       this.updateStatus.bind(this);
       this.getSessionValue.bind(this)
       this.verifyCallback.bind(this);
       this.recaptchaLoaded.bind(this);
    }

    getSessionValue(sessionName){
      let value = ""
      let match = document.cookie.match(new RegExp('(^| )' + sessionName + '=([^;]+)'));
      if (match) {
        value = match[2];
      }
      return value;
    }
    
    updateStatus(stat, apiToken){

      if(stat === 1){
        this.setState({
            loggedInStatus: "LOGGED_IN",
            api_token: apiToken
        });
      }else{
        this.setState({
          loggedInStatus: "NOT_LOGGED_IN",
          user:{},
          error:true
        });
      }
       // 
    }

    recaptchaLoaded(){
      console.log('Recaptcha loaded!');
    }

    verifyCallback = recaptchaResponse => {
      this.setState({
        verified: true
      });
    };

    handleResetPassSuccess=(e)=>{
      
      e.preventDefault();
      document.location.href="/";
    }
    validateEmail=(e)=>{
      let emailAddress = this.state.email
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validEmail= re.test(String(emailAddress).toLowerCase());
      if(validEmail){
        this.setState({
          error:false,
          validEmail:true,
          stage1:false
        })
        let stringifiedEMail = JSON.stringify(emailAddress);
        axios.post(`${API_URL}reset_email`, {
          userEmail: stringifiedEMail
        }).then(response => {
          if(response.data.status){
            this.setState({
              emailSentMsg:response.data.message
            })
          }
          //{"status":true,"message":"We have sent you an email with a link to reset your password!"}

        })
        
      }else{
        this.setState({
          error:true,
          validEmail:false,
          stage1:false
        })
      }
      // if(emailAddress.value.match(eMailformat)){
      //   console.log("matched")
      // }else{
      //   console.log("did not match")
      // }
      //e.preventDefault();
    }
    

    handleResetPassForm=(e)=>{
      e.preventDefault();
      this.validateEmail()
      
    }
    handleResetPass=(e)=>{
      this.setState({
        loginInterface:0
      })
      
      e.preventDefault()
    }

    handelSubmit = (event)=>{
        const{email, password} = this.state;
        var secretKey = "nimnis";
        var hasher = sha512.hmac(secretKey);
        var passwordHash  = hasher.finalize(password);
        var hashedPassword = md5(passwordHash.toString('hex'));
        let md5Email = md5(email);
        const loginData = {
          username:md5Email,
          password:hashedPassword
        }
        let loginObject = JSON.stringify(loginData);
        
        axios.post(`${API_URL}login`, {
          loginObject: loginObject
        }).then(response => {
          if(response.status === 200 && response.data.message === "Success login"){
            localStorage.setItem('monitendUser', response.data.data.username);
            localStorage.setItem('api_token', response.data.api_token);
           
           ReactGA.event({
             category:'Login',
             action:'User Login'
           })
            localStorage.setItem('allCodeAccess', response.data.data.all_codes_access);
            api_token = response.data.api_token;
            document.cookie = "MNSession="+response.data.token;
            this.setState({
              loggedInStatus: "LOGGED_IN",
              user:response.data.data,
              api_token: api_token,
            });
            // _BART("init",{
            //     groupId: "{group Id}",
            //     userId: '{user Id}'
            // });
            // var bart_data = { url: window.location.href } ;
            // _BART('push','freq',bart_data);
          }      
        })
        .catch(error => {
          this.setState({
            loggedInStatus: "NOT_LOGGED_IN",
            user:{},
            error:true
          });
          ReactGA.event({
            category:'Failed Login Attempt',
            action:'Failed User Login'
          })
        });

        event.preventDefault();
    }

    handelChange = (event)=>{
      let targetField = event.target;
      this.setState({
          [targetField.name] : targetField.value
      });
    }
    
    checkLoginStatus(){
        let userid = localStorage.getItem('monitendUser');
        let apiToken = localStorage.getItem('api_token');
        //let monitendSession = sessionStorage.getItem('monitend_session');
        // let MNSession = this.getSessionValue('MNSession');
       
        // let notLoggedIn = false;
        
        if(userid !== null && apiToken !== null){
          this.setState({
            loggedInStatus: "LOGGED_IN",
            api_token: apiToken
          });         
          
          
          
          
        }else{
          this.setState({
            loggedInStatus: "NOT_LOGGED_IN",            
          });                
        }
      }
    componentDidUpdate(){
      if(api_token !== ""){
        this.setState({
          loggedInStatus: "LOGGED_IN",
          api_token: api_token
        });

      }
    }
    componentDidMount(){    
      initReactGA();
      loadPageView();
      this.checkLoginStatus();
      _BART(window,document,'script','https://bart.bonnieraws.dergan.net/bart.js','_BART');
    }
    
    
    render() {

      let initialView;
      if(this.state.loggedInStatus === "LOGGED_IN") {
        let searchTerm = {};
        let seacrObject = JSON.stringify(searchTerm);
        localStorage.setItem('monitendSearchTerm', seacrObject);
        api_token = this.state.api_token
        //tutorial_active
        return (
          <Redirect to={{
            pathname: '/dashboard',
            state: { loggedInStatus: 'LOGGED_IN', user:this.state.user, api_token:api_token}
            }}
          />
        )
      }
      if(this.state.loginInterface === 1){
        initialView = (
          <div className="main-div">
              <div className="Login-title"> 
                <span className="title-text">Login Customers</span>
              </div>
              <div className="logo-container">
                <img src={logo} alt="Logo" />
              </div>
              {/* <div className="welcome-text-container">
                  <p className="welcome-text">Log in to get started!</p>
              </div> */}
              <div className={this.state.error ? 'error-text-show': 'error-text-hide'} >
                  <p className="error-text">Wrong username or password</p>
              </div>
              <div className="form-container">
                <Form onSubmit={this.handelSubmit} className="form">
                  <Col>
                    <FormGroup>
                      <Input  type="email"  name="email" autoComplete="email"  
                        id="exampleEmail"  
                        placeholder="email address"
                        value={this.state.email}
                        onChange={this.handelChange}  
                        required
                        className="input"  
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input type="password" name="password" autoComplete="password"  
                        id="examplePassword"  
                        placeholder="password"
                        value={this.state.password}
                        onChange={this.handelChange}
                        required  
                        className="input"  
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <span>Have you <a href="#" onClick={this.handleResetPass}>lost your password?</a></span>
                    </FormGroup>
                  </Col>
                  
                  <input type="submit" value="Login" className="custom-btn"></input>
                </Form>
              </div>
            </div>
        )
      }
      if(this.state.loginInterface===0){
        initialView = (
          <div className="main-div">
              <div className="Login-title"> 
                <span className="title-text">Reset password</span>
              </div>
              <div className="logo-container">
                <img src={logo} alt="Logo" />
              </div>
              {
                
                /* <div className="welcome-text-container">
                  <p className="welcome-text">Log in to get started!</p>
              </div> */}
              <div className={(!this.state.error && this.state.validEmail && !this.state.stage1) ? 'error-text-show': 'error-text-hide'} >
                  <p className="notification_text">{this.state.emailSentMsg}</p>
              </div>
              <div className={(this.state.error && !this.state.validEmail && !this.state.stage1) ? 'error-text-show': 'error-text-hide'} >
                  <p className="error-text">Wrong e-mail address</p>
              </div>
              <div className="form-container">
                <Form onSubmit={(!this.state.error && this.state.validEmail && !this.state.stage1) ?this.handleResetPassSuccess: this.handleResetPassForm} className="form">
                  <Col className={(!this.state.error && this.state.validEmail && !this.state.stage1)?'error-text-hide':""}>
                    <FormGroup>
                      <Input  type="email"  name="email" autoComplete="email"  
                        id="exampleEmail"  
                        placeholder="email address"
                        value={this.state.email}
                        onChange={this.handelChange}  
                        required
                        className="input"  
                      />
                    </FormGroup>
                    <FormGroup>
                      <Recaptcha 
                        sitekey="6LcbSqYZAAAAAMpa1-3eaYGQUVzWkjfuYDcvV_0b" 
                        verifyCallback={this.verifyCallback}
                        render="explicit"
                        onloadCallback={this.recaptchaLoaded}
                        />
                    </FormGroup>
                  </Col>
                  <input type="submit" value={(!this.state.error && this.state.validEmail && !this.state.stage1)?'Home':'Submit'} disabled={!this.state.verified} className="custom-btn"></input>
                </Form>
              </div>
            </div>
        )
      }

      return (
        <div className="login-box">
          <Container className="loginContainer round-border">
            {initialView}
          </Container>
        </div>
        );
    }
}

export default Home;