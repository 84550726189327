import React, { Component } from 'react';
import {API_URL} from './constants'
import axios from 'axios';
import spinning_pill from '../images/pill.png'
import {Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader,ModalBody, ModalFooter, Button  } from 'reactstrap';
import {Chart} from "react-google-charts";
//import GoogleViz from 'react-google-charts/dist/'


const arrowLeft = <FontAwesomeIcon icon={ faAngleLeft} />
const arrowRight = <FontAwesomeIcon icon={ faAngleRight} />
const arrowDoubleLeft = <FontAwesomeIcon icon={ faAngleDoubleLeft} />
const arrowDoubleRight = <FontAwesomeIcon icon={ faAngleDoubleRight} />
const formatConfig = {
  //style: "currency",
  //currency: "SEK", // kr is showing instead of SEK that is why commented out
  minimumFractionDigits: 2 
};
let dataObj = {};
let prodData = [];

let currentPage = 1;
let initialNumber  = 1;
let lastNumber = 0;
let numberofRows=0;
let singleRowHeight = 31; //height of table rows are 31px
let previousSort = "";
let apiResponseForModal = [];
let valueToDraw = [     
];
let columns = [
  {
    label: 'ATC code',
    field: 'code',
    sort: 'asc',
   
  },
  {
    label: 'Substance',
    field: 'se',
    sort: 'asc',
   
  },
  {
    label: 'Company',
    field: 'company',
    sort: 'asc',
   
  },
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
   
  },
  
  {
    label: 'Strength',
    field: 'strength',
    sort: 'asc',
   
  },
  {
    label: 'Size',
    field: 'size',
    sort: 'asc',
   
  },
 
  {
    label: 'Form',
    field: 'form',
    sort: 'asc',
   
  },
  
  {
    label: 'NAN',
    field: 'nan',
    sort: 'asc',
    
  },
  {
    label: 'Min. Price NAN',
    field: 'lowest_price_nan',
    sort: 'asc',
    
  },
  
  {
    label: 'Min. Price Prod.',
    field: 'lowest_price_prod',
    sort: 'asc',
    
  }
    
  ];
  

  let notSortable = [
    'lowest_price_nan', 'lowest_price_prod'
  ]
  let sameSearch = 0;  
  let searchTerm = {};
  let oldSearchTerm = {};
//let totalRows = 0;
export default class Products extends Component {
    constructor(prop){
        super(prop);
        this.state = {
            data: [],
            totalRows: 0,
            searchTerm:this.props.searchTerm,
            loading:true,
            searchTermLoaded:0,
            totalRowsFound :0,
            clickedSearch : 0,
            orderBy:"",
            orderDirection: "asc",
            totalPage:0,
            currentPage:1,
            onlyValid:true,
            nextOrPrevClicked:false,
            numberofRows:this.props.numberofRows,
            modalApiResponse:[],
            modal:false,
             
            formAlert:false,
            emptyModal:false,
            modalResponse:""          
        
        };
        searchTerm = this.props.searchTerm
        let data = this.props.searchTerm
       
        this.doNothing.bind(this);
        this.sortOnFly.bind(this);
        this.checkIfSameSearchString.bind(this);
        this.renderFiguersAndCharts.bind(this);
        this.sort.bind(this)
 
    }

    renderFiguersAndCharts=(e, modalData="", row)=>{
      this.setState({
        modalApiResponse:[]
      })

      let dataRow = row;
     

      if(modalData ==="lowest_price_nan"){
        this.setState({
          modalData: modalData,
          objForModal:row
        })
        let lowestPriceNanObj = {
          'nan': row.nan,
          'onlyValid': this.state.onlyValid
        }
        let seacrObject = JSON.stringify(lowestPriceNanObj);
        
        
        axios.post(`${API_URL}lowestprice`, {
          seacrhObj: seacrObject
        }).then(response => {
          if(response.status === 200 && response.data.data.length >0){
            apiResponseForModal = response.data.data            
            this.setState(
              {modalApiResponse: response.data.data}
            )
          }
          
        })
      }
      
      if(modalData === "lowest_price_prod"){
        this.setState({
          modalData: modalData,
          objForModal:row
        })
        let lowestPriceProdObj = {
          'strength': row.strength,
          'size': row.size,
          'atc_code_id':row.atc_code,
          'form':row.form,
          'onlyValid': this.state.onlyValid
        }
        let seacrObject = JSON.stringify(lowestPriceProdObj);
        
        axios.post(`${API_URL}lowestprice`, {
          seacrhObj: seacrObject
        }).then(response => {
          if(response.status === 200 && response.data.data.length >0){
            apiResponseForModal = response.data.data            
            this.setState(
              {modalApiResponse: response.data.data}
            )
          }
          
        })
      }
      this.setState(
        {
          modal: !this.state.modal,          
        }
      )
      e.preventDefault();      
    }

    doNothing=()=>{
      return;
    }

    sort = (field, reverse, datType, primer) => {
      let key = "";
      if(datType === 'numeric'){
        key = primer ?
        function(x) {
          let val =primer(x[field])
          if (isNaN(val)) {
            return 0;
          }
          return val;
        } :
        function(x) {
          return x[field]
        };
      }else{
        key = primer ?
        function(x) {          
          return primer(x[field]);
        } :
        function(x) {
          return x[field]
        };
      }
        
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      }
    }

    // sort(key, order='desc'){
    //   return function(a, b) {
    //     if(!a.hasOwnProperty(key) || 
    //        !b.hasOwnProperty(key)) {
    //       return 0; 
    //     }
    //     const varA = (typeof a[key] === 'string') ? 
    //       a[key].toUpperCase() : a[key];
    //     const varB = (typeof b[key] === 'string') ? 
    //       b[key].toUpperCase() : b[key];
          
    //     let comparison = 0;
    //     if (varA > varB) {
    //       comparison = 1;
    //     } else if (varA < varB) {
    //       comparison = -1;
    //     }
    //     return (
    //       (order === 'desc') ? 
    //       (comparison * -1) : comparison
    //     );
    //   };

    // }

    sortOnFly=(e)=>{
      let orderBy = "";
      let initialSort = previousSort;
      
      if(e.currentTarget.id !== ''){
        orderBy = e.currentTarget.id;
      }
      console.log(orderBy)
      previousSort = orderBy;
      
      let orderDirection;
      if(initialSort !== orderBy && this.state.orderDirection !==''){
        if(initialSort !==''){
          let initialDirection  = this.state.orderDirection;
          let element = document.getElementById(initialSort+'_'+initialDirection);
          element.classList.add("hidden");

        }
        orderDirection = 'asc'; 
      }else{
        if(this.state.orderDirection === ''){
          orderDirection = 'asc'        
        }else if(this.state.orderDirection === 'asc'){
          orderDirection = 'desc'        
        }else if(this.state.orderDirection === 'desc'){
          orderDirection = ''                
        }

      }
      this.setState({
        orderBy:orderBy,
        orderDirection:orderDirection          
      })

      let searchT =  localStorage.getItem('monitendSearchTerm')
      if(searchT !== "{}" && searchT !== 'undefined'&& searchT !== null  ){
        let termForDownload = this.state.searchTerm;
        termForDownload.productOrder = orderBy;
        termForDownload.productOrderDir = orderDirection;
        let seacrObject = JSON.stringify(termForDownload);
        localStorage.setItem('monitendSearchTerm', seacrObject);
        
      }

      if(orderDirection !== ""){
        const { data} = this.state;
        //data.sort(this.sort(orderBy, orderDirection))    
        let numericField = ["nan", 'strength','size'];
        if(numericField.includes(orderBy)){
          let reverse  = (orderDirection==='desc')? true:false;
          data.sort(this.sort(orderBy, reverse,'numeric', parseFloat))
        }else{
          let reverse  = (orderDirection==='desc')? true:false;
          data.sort(this.sort(orderBy, reverse,'string', (a) =>  a))
        }
        
        
      }else{
         let seacrhObj =  this.state.searchTerm
          let seacrObject = JSON.stringify(seacrhObj);
          
          axios.post(`${API_URL}get_articles`, {
            seacrhObj: seacrObject
          }).then(response => {
            if(response.status === 200 && response.data.data.length >0){
        
        
              this.setState({
                  data: response.data.data,
                  loading: true,
              })
          }else{
              this.setState({
                  data: [],
                  loading: false,
                  totalRowsFound: response.data.rowsFound[0].total_row,
              })
            } 
          }).catch( (err) => {
            document.location.href="/";
            // this.setState({
            //   forceLogOut:true
            // })
          })
              
       
      }
      var tableId = e.currentTarget.hasAttribute("scope") ?"table_1":"table_2";
      // let sameSearch = false;
      // if(initialSort === orderBy){
      //   sameSearch =
      // }

      var rows = document.getElementById("productTable").getElementsByTagName("tbody")[0].getElementsByTagName("tr").length;
      let totalEntries = rows;
      if(rows<numberofRows){
        totalEntries = rows;
      }
      if(rows<numberofRows){
        totalEntries = rows;
      }
      if(initialSort !== ''){
        let prevAscElement = document.getElementById(initialSort+'_asc');
        let prevDescElement = document.getElementById(initialSort+'_desc');

        if(orderDirection === 'asc'){
          prevDescElement.classList.add("hidden");          
        }else if(orderDirection === 'desc'){
          prevAscElement.classList.add("hidden");          
        }

        let removeHeaderHighlight = document.getElementById(initialSort);
        removeHeaderHighlight.classList.remove("contract-price");
        for(let i=0; i<totalEntries; i++){
          let dataCell = document.getElementById(i+'_'+initialSort);
          dataCell.classList.remove("contract-price");
        }
       
        
      }
      let element = document.getElementById(orderBy+'_'+orderDirection);
      let headerToHighlight = document.getElementById(orderBy);
      headerToHighlight.classList.add("contract-price");
      for(let i=0; i<totalEntries; i++){
        let dataCell = document.getElementById(i+'_'+orderBy);
        dataCell.classList.add("contract-price");
      }
      
     
    if(orderDirection !==""){
      element.classList.remove("hidden");
    }else{
      // this.setState({
      //   data:this.state.initialData
      // })
      
      let previousDirection = this.state.orderDirection;
      let element = document.getElementById(orderBy+'_'+previousDirection);
      element.classList.add("hidden");
      let removeHeaderHighlight = document.getElementById(orderBy);
        removeHeaderHighlight.classList.remove("contract-price");
        for(let i=0; i<totalEntries; i++){
          let dataCell = document.getElementById(i+'_'+orderBy);
          dataCell.classList.remove("contract-price");
        }
        orderBy = "";
        this.setState({ 
          orderDirection:"",
          orderBy : "" 
        })
        
    }
  }

   
    componentDidMount(){
      const search = localStorage.getItem('monitendSearchTerm');
      let searchTerm = JSON.parse(search)
      if(searchTerm !==null &&  typeof searchTerm !== 'undefined') {
        if(Object.keys(searchTerm).length > 0){
          searchTerm.currentPage = this.state.currentPage;
          let seacrObject = JSON.stringify(searchTerm);
          
          this.setState({
              searchTerm:searchTerm,
              searchTermLoaded : 1,
              clickedSearch : 1,
              onlyValid: seacrObject.onlyValid
          })
          
          axios.post(`${API_URL}get_articles`, {
            seacrhObj: seacrObject
          }).then(response => {
                  if(response.status === 200 && response.data.data.length >0){
                      let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
                      //prodData = response.data.data;
                      this.setState({
                          data: response.data.data,
                          totalRowsFound:response.data.rowsFound[0].total_row,
                          totalPage : totalPage,
                      })
                  }else if(response.status === 201){
                    this.setState({
                      formAlert:true,
                      loading:false,
                      emptyModal:!this.state.emptyModal,
                      modalResponse:response.data.EmptySearch
                     
                    })
                  }else{
                      this.setState({
                          data: [],
                          loading: false,
                          totalRowsFound: response.data.rowsFound[0].total_row,
  
                          
                      })
                  }                 
              }).catch( (err) => {
                document.location.href="/";
              })
          }
      }
      
        
    }
    checkIfSameSearchString(str1, str2){
      let sameString = true;
      let keys = Object.keys(str2)
      for (let key of keys) {
        if(key === 'productOrder'){
          continue;
        }
        if(key === 'productOrderDir'){
          continue;
        }
        let t1 = !(key in str1)? "":str1[key];
        let t2 = !(key in str2)? "":str2[key];
        if( JSON.stringify(t1).trim() !== JSON.stringify(t2).trim()){
          return false;
        }        
      }
      return sameString;
      

    }
    componentDidUpdate(prevProps) {
      
      searchTerm = this.props.searchTerm;
      oldSearchTerm = prevProps.searchTerm;
      numberofRows = this.props.numberofRows;
      if(searchTerm !== null){
        sameSearch = this.checkIfSameSearchString(oldSearchTerm, searchTerm);
        if(!sameSearch){
          let searchTerm = this.props.searchTerm;
          if(Object.keys(searchTerm).length > 0){
            let seacrObject = JSON.stringify(searchTerm);
            this.setState({
                searchTerm:searchTerm,
                searchTermLoaded : 1,
                clickedSearch:1,
                data:[],
                numberofRows:numberofRows,
                onlyValid: searchTerm.onlyValid   ,
                currentPage: searchTerm.currentPage,
                loading:true                
            })
            
              axios.post(`${API_URL}get_articles`, {
                seacrhObj: seacrObject
              }).then(response => {
                    if(response.status === 200 && response.data.data.length >0){
                        //prodData = response.data.data;
                        let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
                        this.setState({
                          data: response.data.data,
                          totalRowsFound:response.data.rowsFound[0].total_row,
                          totalPage: totalPage,
                          loading:false
                        })
                    }else if(response.status === 201){
                      this.setState({
                        formAlert:true,
                        loading:false,
                        emptyModal:!this.state.emptyModal,
                        modalResponse:response.data.EmptySearch
                       
                      })
                    }else{
                        this.setState({
                            data: [],
                            loading: false,
                            totalRowsFound: response.data.rowsFound[0].total_row,
                        })
                    }                     
                }).catch( (err) => {
                  document.location.href="/";
                })
            }
          }
        }
        
      }
    render() {
      //console.log(Chart);
      let renderProductTable;
      let renderTableHeader;
      let showSpinningPill = true;
      let renderTableBody;
      let renderPagination;
      let renderModalBody="";
      let modalTitle;
      
      initialNumber  = (currentPage-1)*numberofRows+1;
      lastNumber = currentPage*numberofRows;
      if(this.state.totalRowsFound>0){
        renderPagination=(
          <div className="pagination_style">
            <div className="product_counter">{this.state.totalRowsFound}{(this.state.totalRowsFound>1?" records":" record")}  found</div>
            {/* <div className="pagination_container">
                <span className={(this.state.currentPage ===1 ) ? 'disabled_link': 'regular_link'}  onClick={this.goFirstPage} >{arrowDoubleLeft}</span> &nbsp; &nbsp;
                <span className={(this.state.currentPage ===1 ) ? 'disabled_link': 'regular_link'}  onClick={this.goPrevPage} >{arrowLeft}</span> &nbsp;
                    {this.state.currentPage} / {this.state.totalPage} &nbsp;
                <span className={(this.state.currentPage === this.state.totalPage ) ? 'disabled_link': 'regular_link'}  onClick={this.goNextPage} >{arrowRight}</span> &nbsp; &nbsp;
                <span className={(this.state.currentPage === this.state.totalPage ) ? 'disabled_link': 'regular_link'}  onClick={this.goLastPage} >{arrowDoubleRight}</span>
            </div> */}
            
          </div>
          
        )
      }

        if(this.state.totalRowsFound>0){
            renderTableBody = ( 
              <tbody>
                {this.state.data.map((row, index) => {
                   let lowestPriceNan = new Intl.NumberFormat('sv-SE',formatConfig).format(row.lowest_price_nan)
                   let lowestPricePord = new Intl.NumberFormat('sv-SE',formatConfig).format(row.lowest_price_prod)
                  return (
                    <tr className="product_row" key={index} id={index}>
                        <td id={index+'_code'} className="text-center">{row.atc_code_id}</td>
                        <td id={index+'_se'} className="text-left">{row.se}</td>                        
                        <td id={index+'_company'} className="text-left">{row.company}</td>
                        <td id={index+'_name'} className="text-left">{row.name}</td>
                        <td id={index+'_strength'} className="text-right">{row.strength}</td>
                        <td id={index+'_size'} className="text-right">{row.size}</td>
                        <td id={index+'_form'} className="text-left">{row.form}</td>
                        <td id={index+'_nan'} className="text-center">{row.nan}</td>
                        <td id={index+'_lowest_price_nan'} className="text-right" onClick={row.lowest_price_nan>0 ? (e)=>this.renderFiguersAndCharts(e, "lowest_price_nan", row):this.doNothing}>
                          <a href="#"  className={(row.lowest_price_nan !== '' ) ? "highlightText":"regularText"}>{row.lowest_price_nan>0 ? lowestPriceNan+' SEK':''}</a>
                        </td>
                        <td id={index+'_lowest_price_nan'} className="text-right" onClick={row.lowest_price_prod>0 ? (e)=>this.renderFiguersAndCharts(e, "lowest_price_prod", row):this.doNothing}>
                          <a href="#"  className={(row.lowest_price_prod !== '' ) ? "highlightText":"regularText"}>{row.lowest_price_prod>0 ? lowestPricePord+' SEK':''}</a>
                        </td>
                        
                    
                    </tr>
                  );
                })}
              </tbody> 
            );
          }

        renderTableHeader = (
            <tr>
              {columns.map((suggestion, index) => {
                return (
                  <th key={index} id={suggestion.field} 
                    className={suggestion.field === 'contract_price' ? ( notSortable.includes(suggestion.field )? 'contract-price not_clickable':'contract-price clickable'):(notSortable.includes(suggestion.field )? ' not_clickable':' clickable')} 
                    onClick = {notSortable.includes(suggestion.field )? this.doNothing:this.sortOnFly}
                    
                    // className = {notSortable.includes(suggestion.field )? "not_clickable":"clickable" } 'contract-price'
                  >
                    {/* {suggestion.label}  orderBy:"",
            orderDirection: "ASC",  */}
                      
                        {suggestion.label}
                        &nbsp;
                       {notSortable.includes(suggestion.field )? <span>&nbsp;</span>: <span id={suggestion.field+'_asc'} className="asc hidden">▲</span>}
                       {notSortable.includes(suggestion.field )? <span>&nbsp;</span>: <span id={suggestion.field+'_desc'} className="desc hidden">▼</span>}
                      
                      
                      {/* <span className={(suggestion.field === this.state.orderBy && this.state.orderDirection === "ASC")? "bold_sort_icon":"upArroedimmed_sort_icon" }>{angleUp}</span>  */}
                      
                  </th>
                );
              })}
            </tr>
          );
          if(this.state.modalData === "lowest_price_nan" || this.state.modalData === "lowest_price_prod"){
            modalTitle = (
              <span>Contract Prices</span>
            )
          }
          if(this.state.modalData === "lowest_price_nan" || this.state.modalData === "lowest_price_prod" ){

            let dataToDraw = [];
            valueToDraw = [];
            const options = {
              vAxis: { title: "Prices (SEK)", viewWindow: { min: 0 } },
              legend: "none"
            };
            valueToDraw.push(["Region", "Contract Prices (SEK)"])
          
            if(this.state.modalApiResponse.length>0){
              
             dataToDraw=  this.state.modalApiResponse.map(obj =>{
                //var rObj = [obj.county_name, formatNumber.formatValue(Number(obj.contract_price)) ];
                var rObj = [obj.county_name, Number(obj.contract_price) ];
                //var rObj = [obj.county_name, Number(obj.contract_price), '#1a427b', obj.contract_price+' SEK'];
                valueToDraw.push(rObj)
                return rObj;
                
              })
              
            }
            renderModalBody =  (this.state.modalApiResponse.length>0)
            ? (
              <div id="modal_body">
                <table className="description_contract_price">
                  <tbody>
                    <tr className="modal_substance_header">
                      <td colSpan="3"><b>{this.state.objForModal.en}</b></td>
                    </tr>
                    <tr>
                      <td><b>Strength: {this.state.objForModal.strength}</b></td><td><b> Size: {this.state.objForModal.size}</b></td><td><b>Form: {this.state.objForModal.form}</b></td>                    
                    </tr>
                  </tbody>
                </table>
                <div>
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={valueToDraw}
                    options={options}
                    formatters={[
                      {
                        type: 'NumberFormat',
                        column: 1,
                        options: {
                          fractionDigits: 2,
                          groupingSymbol: ' ',
                          decimalSymbol:','
                          
                        },
                      },
                    ]}
                    
                  />               
                </div>
              </div>
              
              
            ):(
              <div>loading...</div>
            )
           
          }

        
        if(this.state.data.length > 0 && this.state.clickedSearch === 1 && !this.state.nextOrPrevClicked){
            showSpinningPill = false;
            renderProductTable = (
                <table id="productTable">
                <thead>
                    {renderTableHeader}
                </thead>      
                {renderTableBody}                
            </table>
                
            )
          }else if(  this.state.clickedSearch === 1 || ( this.state.clickedSearch === 1 && this.state.nextOrPrevClicked)){
            showSpinningPill = true;
            if(this.state.loading){
                renderProductTable = (
                    <div className={(showSpinningPill) ? 'spinning_pill': 'spinning_pill_hidden'}>
                                
                                <img src={spinning_pill} alt="spinning_pill" />
                    </div>              
                )
            }else{
                renderProductTable = (
                    <div className="no_data_found">No records found</div>            
                )
            }
            
        }else if(this.state.totalRowsFound === 0 && this.state.clickedSearch === 0 ){
            showSpinningPill = false;
            renderProductTable = (
                <div className="no_data_found">Search for products</div>            
            )
        }
        let modalBodyFormAlert = "";
        if(this.state.formAlert){
          modalBodyFormAlert = (
            
            <Modal style=  {{maxWidth: '600px', width: '600px'}}  isOpen={this.state.emptyModal} toggle={this.toogleEmptyModal}>
                <ModalHeader  toggle={this.toogleEmptyModal} charCode="x" className="modal_title">Empty search form!</ModalHeader>
                <div className="form_alert"><p> {this.state.modalResponse}</p></div>
                <ModalFooter> 
                  <button onClick={this.toogleEmptyModal} type="button" className="form-button">Ok</button>                 
                </ModalFooter>
              </Modal>
          )
        }
        
        return (
            
            <div>
                
                <div id="main-table-wrapper" style={{overflowX:"scroll"}}>
                {renderPagination}
                    {renderProductTable}
                </div>
                <div style={{width:'100vw'}}>
                  {modalBodyFormAlert} 
                </div>
                <div style={{width:'90vw'}}>
                <Modal size="lg" style={this.state.modalData === "contract_price"? {maxWidth: '70vw', width: '80%'}:{maxWidth: '95vw', width: '80%'}}  className="main_modal" isOpen={this.state.modal} toggle={this.renderFiguersAndCharts}  >
                  <ModalHeader toggle={this.renderFiguersAndCharts} charCode="x" className="modal_title">{modalTitle}</ModalHeader>
                  <ModalBody className="modalBody">
                    {renderModalBody}
                  </ModalBody>
                  <ModalFooter> 
                    <button onClick={this.renderFiguersAndCharts} type="button" className="form-button">Close</button>                 
                  </ModalFooter>
                </Modal>
                </div>
            </div>
            
        );
    }
}