import React, { Component } from 'react';
import axios from 'axios'
import {API_URL} from './constants'
import {Button} from 'reactstrap'

class UpdateBartUsers extends Component {
    constructor(props){
        super(props)
        this.state={
            adminUser:false,
            token:"",
            responseData:{},
            usersList:{}
        }

    this.sendRequestToBart.bind(this)
    this.formObjectTtoSendReq.bind(this)
   
    }
    formObjectTtoSendReq=()=>{
        let usersListObj = {}
        let users=[]
        
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        let today=  [year, month, day].join('-');
        
        this.state.responseData.map((row, index)=> {
            let user=[]
            user["userId"] = row.id;
            user["date"] = today;
            let data = []
            data['name'] = row.first_name+' '+row.last_name;
            data['company'] = row.company;
            let arrayToS= JSON.stringify(Object.assign({}, data));
            let dataObj = JSON.parse(arrayToS); 
            user['data'] = dataObj
            let arrayToString = JSON.stringify(Object.assign({}, user));
            let userObj = JSON.parse(arrayToString); 
            users.push(userObj);
           
        })
        usersListObj['users'] = users
        let arrayToString= JSON.stringify(Object.assign({}, usersListObj));
        usersListObj = JSON.parse(arrayToString); 
        this.setState({
            usersList:usersListObj
        })
        
    }

    sendRequestToBart=()=>{
        //console.log(`${API_URL}usersList?api_token=${this.state.token}`)
        let apiToken = localStorage.getItem('api_token');
        axios.post(`${API_URL}usersList`, {
            api_token: apiToken
          }).then(response => {
            if(response.status === 200 && (Object.keys(response.data)).length >0){
                this.setState({
                    responseData: response.data
                })
                this.formObjectTtoSendReq();
                let seacrObject = JSON.stringify(this.state.usersList);
                console.log(seacrObject)
            }
          }).catch((error) => {
           console.log(error)
          })

    }

    componentDidMount(){
        let apiToken = localStorage.getItem('api_token');
        axios.post(`${API_URL}user`, {
            api_token: apiToken
          }).then(response => {
            if(response.status === 200 && (Object.keys(response.data.data)).length >0){
                if(response.data.data.company === 1){
                    this.setState(
                        {
                            adminUser:true,
                            token:apiToken
                        }
                    )
                }
            }
          }).catch((error) => {
            document.location.href="/";
            
          })
    }
    render() 
    {
        console.log(this.state.usersList)
        
            return (
                <div>
                   <Button onClick={this.sendRequestToBart}>Update Users</Button>
                </div>
            )
        
        
    }
}

export default UpdateBartUsers;