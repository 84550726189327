import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import {API_URL} from './constants'
import axios from 'axios';
import spinning_pill from '../images/pill.png'
import {Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import Chart from "react-google-charts";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';


import { Modal, ModalHeader,ModalBody, ModalFooter, Button ,Table } from 'reactstrap';



const isIE = /*@cc_on!@*/false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;


// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

let div1Height = '82vh';
let div2Height = '85vh'; // chrome, safari, edge        

const formatConfig = {
  //style: "currency",
  //currency: "SEK", // kr is showing instead of SEK that is why commented out
  minimumFractionDigits: 2 
};


const arrowLeft = <FontAwesomeIcon icon={ faAngleLeft} />
const arrowRight = <FontAwesomeIcon icon={ faAngleRight} />
const arrowDoubleLeft = <FontAwesomeIcon icon={ faAngleDoubleLeft} />
const arrowDoubleRight = <FontAwesomeIcon icon={ faAngleDoubleRight} />

let dataObj = {};
let prodData = [];

let currentPage = 1;
let initialNumber  = 1;
let lastNumber = 0;
let numberofRows=0;
let singleRowHeight = 31; //height of table rows are 31px
let previousSort = ""
let timesSorted = 0;
let fixedCol = ['county_name','substance', 'company', 'name', 'strength', 'size', 'atc_code'];
let currentRowNum = "";
let lastRowNum  = "";

let columns = [
  {
    label: 'Region',
    field: 'county_name',
    sort: 'asc',
    
  },
  {
    label: 'ATC',
    field: 'atc_code',
    sort: 'asc',
   
  },
  {
    label: 'Substance',
    field: 'substance',
    sort: 'asc',
   
  },
  {
    label: 'Company',
    field: 'company',
    sort: 'asc',
   
  },
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
   
  },
  {
    label: 'Strength',
    field: 'strength',
    sort: 'asc',
   
  },
  {
    label: 'Size',
    field: 'size',
    sort: 'asc',
   
  },
  
  
  {
    label: 'Form',
    field: 'form',
    sort: 'asc',
   
  },

  {
    label: 'NAN',
    field: 'nan',
    sort: 'asc',
    
  },
  {
    label: 'Approx. AUP',
    field: 'approx_aup',
    sort: 'asc',
    
  },
  {
    label: 'Current AIP',
    field: 'current_aip',
    sort: 'asc',
    
  },
  {
    label: 'Contract price',
    field: 'contract_price',
    sort: 'asc',
    
  },
  
  {
    label: 'Discount',
    field: 'discount',
    sort: 'asc',
    
  },
  
  {
    label: 'Min. Price NAN',
    field: 'lowest_price_nan',
    sort: 'asc',
    
  },
  {
    label: 'Min. Price Prod.',
    field: 'lowest_price_prod',
    sort: 'asc',
    
  },
  {
    label: 'Contract from',
    field: 'contract_from',
    sort: 'asc',
    
  },
  {
    label: 'Contract to',
    field: 'contract_to',
    sort: 'asc',
    
  },
  {
    label: 'Tender Reference no',
    field: 'tender_serial',
    sort: 'asc',
    
  },

  {
    label:'Max Extension to',
    field:'max_extension_to',
    sort: 'asc'
  }
    
];
  let rows = [
  ];

  let notSortable = [
    'discount', 'lowest_price_nan', 'lowest_price_prod'
  ]

  let alterationColumns = [
    {
      label:'Date',fileld:'time'
    },
    {
      label:'Type',fileld:'column_altered'
    },
    {
      label:'Old Value',fileld:'old_value'
    },
    {
      label:'New Value',fileld:'new_value'
    }
  ];
  let apiResponseForModal = [];

  let valueToDraw = [
      
  ];
  let insideTable = false;
  let sameSearch = 0;  
  let searchTerm = {};
  let oldSearchTerm = {};
  //let secondWidth = '50%';
    
  //document.getElementById("element").addEventListener("mouseout", function(  ) {isOnDiv=false;});
//let totalRows = 0;
export default class ProductContracts extends Component {
    constructor(prop){
        super(prop);
        this.state = {
            data: [],
            initialData:[],
            totalRows: 0,
            searchTerm:this.props.searchTerm,
            loading:true,
            searchTermLoaded:0,
            totalRowsFound :0,
            clickedSearch : 0,
            orderBy:"",
            orderDirection: "",
            totalPage:0,
            currentPage:1,
            onlyValid:true,
            nextOrPrevClicked:false,
            modal:false,
            objForModal: {},
            modalData:"",
            modalApiResponse:[],
            numberofRows:this.props.numberofRows,
            forceLogOut: false,
            sortingTimes:0,
            secondWidth:'',
            initialWidthSet:0,
            
            formAlert:false,
            emptyModal:false,
            modalResponse:""
            
        
        };
        searchTerm = this.props.searchTerm
        let data = this.props.searchTerm
       
        this.doNothing.bind(this);
        this.showNothing.bind(this);
        this.renderFiguersAndCharts.bind(this);
        this.base64EncodeUnicode.bind(this);
        this.checkIfSameSearchString.bind(this);
        this.getRowId.bind(this);
        this.addClasses.bind(this);
        this.removeClasses.bind(this);
        
        this.mouseOut.bind(this);
        this.calulateWidth.bind(this);
        this.sortOnFly.bind(this);
        this.sort.bind(this);
        this.handleTable.bind(this);
        this.toogleEmptyModal.bind(this);
        this.highlightSortedColumn.bind(this);
        this.contractWrapper = React.createRef();
       
    }
    highlightSortedColumn=(orderBy, direction)=>{
      //console.log(orderBy, direction)
      //console.log(this.state.numberofRows)
      // const node = this.contractWrapper.current;
      // if(node){
      //   console.log(node);
      // }
      
      // let element = document.getElementById(orderBy+'_'+direction);
      // let headerToHighlight = document.getElementById(orderBy);
      // var rows = document.getElementById('parent_div_1').getElementsByTagName("tbody")[0].getElementsByTagName("tr").length;
      // let totalEntries = rows;
      // if(rows<this.state.numberofRows){
      //   totalEntries = rows;
      // }
      // if(rows<this.state.numberofRows){
      //   totalEntries = rows;
      // }
      // for(let i=0; i<totalEntries; i++){
      //   let dataCell = document.getElementById(i+'_'+orderBy);
      //   dataCell.classList.add("contract-price");
      // }

    }
    toogleEmptyModal=()=>{
      this.setState({
        emptyModal:!this.state.emptyModal,
        
      })
    }
    handleTable=()=>{
      let div1 = document.getElementById('parent_div_1');
        if(div1){
          let mainWindow = document.getElementById('dashboardWindow').offsetWidth;
          let containerHeight = mainWindow.offsetHeight;
          let tabHeight = document.getElementById("walkthrough_salesval").offsetHeight;
          let footerHeight = document.getElementById("footer").offsetHeight;
          
          if(isFirefox ){        
            //let div2Height = (containerHeight+10)-(tabHeight+(footerHeight*2)+5)
            div1Height = (containerHeight)-(tabHeight+(footerHeight*2)+10)
            div2Height = div1Height+17;
            
          }else{
            div1Height = (containerHeight)-(tabHeight+(footerHeight*2))
            div2Height = div1Height+8; // chrome, safari, edge       
          }
          let div2Elem = document.getElementById('parent_div_2');
          let search_pannel = document.getElementById('search_pannel').offsetWidth;
          let widthDiv1 = document.getElementById('parent_div_1').offsetWidth;
          let widthDiv2 = "";
          widthDiv2 = (mainWindow-(widthDiv1+search_pannel+3));
          //widthDiv2 = 100-(search_pannel*2+ (100/mainWindow)*widthDiv1)
          document.getElementById('parent_div_2').style.maxWidth = widthDiv2+"px";
          document.getElementById('parent_div_1').style.maxHeight = div1Height+"px";
          document.getElementById('parent_div_2').style.maxHeight = div2Height+"px";
          
        }
    }
    calulateWidth=(e)=>{
      var mainWindow = document.getElementById('dashboardWindow').offsetWidth;
      var search_pannel = document.getElementById('search_pannel').offsetWidth;
      
      var widthDiv1 = document.getElementById('parent_div_1').offsetWidth;
      var widthDiv2 = "";
      widthDiv2 = (mainWindow-(search_pannel)-(widthDiv1));
      this.setState({
        secondWidth:widthDiv2+'px'
      })
      e.preventDefault();
    }
    scrollDivs=(e)=>{
      let elmnt = document.getElementById('parent_div_1');
      let elmnt2 = document.getElementById('parent_div_2');
      document.getElementById('parent_div_2').scrollTop = elmnt2.scrollTop;
      document.getElementById('parent_div_1').scrollTop = elmnt.scrollTop;
    }
    
    mouseOut=(e)=>{
      //console.log("out")
      this.removeClasses()
    }
    removeClasses=()=>{
      var elems = document.querySelectorAll(".sample_class");
      [].forEach.call(elems, function(el) {
          el.classList.remove("sample_class");
      });
    }
    addClasses=(currentRow, currentTable)=>{    
      let table = (currentTable==="table1")? "table2":"table1"
      let tableId = (currentTable==="table1")? "parent_div_1":"parent_div_2"
      // let tableWhite = document.getElementById(tableId);
      // tableWhite.classList.add("tableWhite");  
      var elems = document.querySelectorAll(".sample_class");
        [].forEach.call(elems, function(el) {
            el.classList.remove("sample_class");
        });
      let item = document.getElementById(table+"_"+currentRow);
      item.classList.add("sample_class");
      
    }
    getRowId=(e)=>{
      let target = e.currentTarget.id;
      let rowNumArr = target.split("_");
      let currentTable = rowNumArr[0];
      let rowNum = rowNumArr[1];
      currentRowNum = rowNum
      this.addClasses(currentRowNum, currentTable)
     
    }
   
    renderFiguersAndCharts=(e, modalData="", row)=>{
      this.setState({
        modalApiResponse:[]
      })

      let dataRow = row;
      if(modalData==="show_nothing"){
        this.setState({
          modalData: modalData,
          objForModal:row
        })
      }
      if(modalData==="contract_price"){
        if(row.hasAlteration===0){
          e.preventDefault();
          return;
        }
        let seacrObject = JSON.stringify(row.contract_id);
        
        axios.post(`${API_URL}priceAlterations`, {
          seacrhObj: seacrObject
        }).then(response => {
          if(response.status === 200 && response.data.data.length >0){
            this.setState(
              {modalApiResponse: response.data.data}
            )
           
            
          }
          
        })

        this.setState({
          modalData: modalData,
          objForModal:row
        })
      }

      if(modalData ==="lowest_price_nan"){
        this.setState({
          modalData: modalData,
          objForModal:row
        })
        let lowestPriceNanObj = {
          'nan': row.nan,
          'onlyValid': this.state.onlyValid
        }
        let seacrObject = JSON.stringify(lowestPriceNanObj);
        
        axios.post(`${API_URL}lowestprice`, {
          seacrhObj: seacrObject
        }).then(response => {
          if(response.status === 200 && response.data.data.length >0){
            apiResponseForModal = response.data.data            
            this.setState(
              {modalApiResponse: response.data.data}
            )
          }
          
        })
      }
      
      if(modalData === "lowest_price_prod"){
        this.setState({
          modalData: modalData,
          objForModal:row
        })
        let lowestPriceProdObj = {
          'strength': row.strength,
          'size': row.size,
          'atc_code_id':row.atc_code_id,
          'form':row.form,
          'onlyValid': this.state.onlyValid
        }
        let seacrObject = JSON.stringify(lowestPriceProdObj);
        
        axios.post(`${API_URL}lowestprice`, {
          seacrhObj: seacrObject
        }).then(response => {
          if(response.status === 200 && response.data.data.length >0){
            apiResponseForModal = response.data.data            
            this.setState(
              {modalApiResponse: response.data.data}
            )
          }
          
        })
      }
     
      this.setState(
        {
          modal: !this.state.modal,          
        }
      )
      e.preventDefault();      
    }
    sort = (field, reverse, datType, primer) => {
      let key = "";
      if(datType === 'numeric'){
        key = primer ?
        function(x) {
          let val =primer(x[field])
          if (isNaN(val)) {
            return 0;
          }
          return val;
        } :
        function(x) {
          return x[field]
        };
      }else{
        key = primer ?
        function(x) {          
          return primer(x[field]);
        } :
        function(x) {
          return x[field]
        };
      }
        
    
      reverse = !reverse ? 1 : -1;
    
      return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
      }
    }
    // sort(key, order='desc'){
    //   return function(a, b) {
    //     console.log(a)
    //     if(!a.hasOwnProperty(key) || 
    //        !b.hasOwnProperty(key)) {
    //       return 0; 
    //     }
    //     const varA = (typeof a[key] === 'string') ? 
    //       a[key].toUpperCase() : a[key];
    //     const varB = (typeof b[key] === 'string') ? 
    //       b[key].toUpperCase() : b[key];
          
    //     let comparison = 0;
    //     if (varA > varB) {
    //       comparison = 1;
    //     } else if (varA < varB) {
    //       comparison = -1;
    //     }
    //     return (
    //       (order === 'desc') ? 
    //       (comparison * -1) : comparison
    //     );
    //   };

    // }
    sortOnFly=(e)=> {
      let orderBy = "";
      let initialSort = previousSort;
      
      if(e.currentTarget.id !== ''){
        orderBy = e.currentTarget.id;
      }
      
      previousSort = orderBy;
      
      let orderDirection;
      if(initialSort !== orderBy && this.state.orderDirection !==''){
        if(initialSort !==''){
          let initialDirection  = this.state.orderDirection;
          let element = document.getElementById(initialSort+'_'+initialDirection);
          element.classList.add("hidden");

        }
        orderDirection = 'asc'; 
      }else{
        if(this.state.orderDirection === ''){
          orderDirection = 'asc'        
        }else if(this.state.orderDirection === 'asc'){
          orderDirection = 'desc'        
        }else if(this.state.orderDirection === 'desc'){
          orderDirection = ''                
        }

      }
      this.setState({
        orderBy:orderBy,
        orderDirection:orderDirection          
      })
     
      let searchT =  localStorage.getItem('monitendSearchTerm')
      if(searchT !== "{}" && searchT !== 'undefined'&& searchT !== null  ){
        let termForDownload = this.state.searchTerm;
        termForDownload.contractOrder = orderBy;
        termForDownload.contractOrderDir = orderDirection;
        let seacrObject = JSON.stringify(termForDownload);
        localStorage.setItem('monitendSearchTerm', seacrObject);
        
      }
      
      if(orderDirection !== ""){
        const { data, initialData } = this.state;
        
        if(initialData.length === 0){
          this.setState({
            initialData:data
          })
        }
        
        let numericField = ["nan", "approx_aup", "current_aip", "contract_price"];
        if(numericField.includes(orderBy)){
          let reverse  = (orderDirection==='desc')? true:false;
          data.sort(this.sort(orderBy, reverse,'numeric', parseFloat))
          
        }else{
          let dateFields = ['max_extension_to','contract_to', 'contract_to']
          
          let reverse  = (orderDirection==='desc')? true:false;
          data.sort(this.sort(orderBy, reverse,'string', (a) => a))
        }
        

        
        //if()
        //data.sort(this.sort(orderBy, orderDirection))    
        //this.setState({ data })
      }else{
         let seacrhObj =  this.state.searchTerm
        let seacrObject = JSON.stringify(seacrhObj);
          axios.post(`${API_URL}search`, {
            seacrhObj: seacrObject
          }).then(response => {
            if(response.status === 200 && response.data.data.length >0){
              let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
              //prodData = response.data.data;
              this.setState({
                  data: response.data.data,
                  totalRowsFound:response.data.rowsFound[0].total_row,
                  totalPage : totalPage,
                  
              })
          }else{
              this.setState({
                  data: [],
                  loading: false,
                  totalRowsFound: response.data.rowsFound[0].total_row,
              })
            } 
          }).catch( (err) => {
            document.location.href="/";
          })
      }

      var tableId = e.currentTarget.hasAttribute("scope") ?"parent_div_1":"parent_div_2";
      var rows = document.getElementById(tableId).getElementsByTagName("tbody")[0].getElementsByTagName("tr").length;
      let totalEntries = rows;
      if(rows<numberofRows){
        totalEntries = rows;
      }
      if(rows<numberofRows){
        totalEntries = rows;
      }
      if(initialSort !== ''){
        let prevAscElement = document.getElementById(initialSort+'_asc');
        let prevDescElement = document.getElementById(initialSort+'_desc');

        if(orderDirection === 'asc'){
          prevDescElement.classList.add("hidden");          
        }else if(orderDirection === 'desc'){
          prevAscElement.classList.add("hidden");          
        }

        let removeHeaderHighlight = document.getElementById(initialSort);
        removeHeaderHighlight.classList.remove("contract-price");
        for(let i=0; i<totalEntries; i++){
          let dataCell = document.getElementById(i+'_'+initialSort);
          dataCell.classList.remove("contract-price");
        }
       
        
      }
      let element = document.getElementById(orderBy+'_'+orderDirection);
      let headerToHighlight = document.getElementById(orderBy);
      headerToHighlight.classList.add("contract-price");
      for(let i=0; i<totalEntries; i++){
        let dataCell = document.getElementById(i+'_'+orderBy);
        dataCell.classList.add("contract-price");
      }
      
     
    if(orderDirection !==""){
      element.classList.remove("hidden");
    }else{
      // this.setState({
      //   data:this.state.initialData
      // })
      
      let previousDirection = this.state.orderDirection;
      let element = document.getElementById(orderBy+'_'+previousDirection);
      element.classList.add("hidden");
      let removeHeaderHighlight = document.getElementById(orderBy);
        removeHeaderHighlight.classList.remove("contract-price");
        for(let i=0; i<totalEntries; i++){
          let dataCell = document.getElementById(i+'_'+orderBy);
          dataCell.classList.remove("contract-price");
        }
        orderBy = "";
        this.setState({ 
          orderDirection:"",
          orderBy : "" 
        })
        
    }
      
  }

    doNothing=(e)=>{
      e.preventDefault();
      return;
    }

    showNothing=(e)=>{
      this.renderFiguersAndCharts(e, "show_nothing")
    }

    

    
    componentDidMount(){
      prodData = this.props.prodData
        const search = localStorage.getItem('monitendSearchTerm');
        let searchTerm = JSON.parse(search)
        if(searchTerm !==null &&  typeof searchTerm !== 'undefined') {
          if(Object.keys(searchTerm).length > 0){
            searchTerm.currentPage = this.state.currentPage;
            searchTerm.contractInfo = false;
            let orderBy = searchTerm.contractOrder;
            let orderDirection = searchTerm.contractOrderDir;
            
            let seacrObject = JSON.stringify(searchTerm);
            
            this.setState({
                searchTerm:searchTerm,
                orderBy:orderBy,
                orderDirection:orderDirection,
                searchTermLoaded : 1,
                clickedSearch : 1,
                onlyValid: searchTerm.onlyValid

            })
            //console.log(`${API_URL}search?seacrhObj=${seacrObject}`)
            axios.post(`${API_URL}search`, {
              seacrhObj: seacrObject
            }).then(response => {
                    if(response.status === 200 && response.data.data.length >0){
                        let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
                        this.setState({
                            data: response.data.data,
                            totalRowsFound:response.data.rowsFound[0].total_row,
                            totalPage : totalPage,
                        })
                    }else if(response.status === 201){
                      this.setState({
                        formAlert:true,
                        loading:false,
                        emptyModal:!this.state.emptyModal,
                        modalResponse:response.data.EmptySearch
                       
                      })
                    }else{
                        this.setState({
                            data: [],
                            loading: false,
                            totalRowsFound: response.data.rowsFound[0].total_row,

                            
                        })
                    }                 
                }).catch( (err) => {
                  document.location.href="/";
                  
                })        
          }
        }      
       
        
    }
    
    base64EncodeUnicode(str) {
      // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters, 
      // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
      let utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
              return String.fromCharCode('0x' + p1);
      });
  
      return utf8Bytes;
    }
    componentWillUpdate(){
      //console.log(this.state.searchTerm)
    }
   
    checkIfSameSearchString(str1, str2){
      let sameString = true;
      let keys = Object.keys(str2)
      for (let key of keys) {
        if(key === 'contractOrder'){
          continue;
        }
        if(key === 'contractOrderDir'){
          continue;
        }
        let t1 = !(key in str1)? "":str1[key];
        let t2 = !(key in str2)? "":str2[key];
        if( JSON.stringify(t1).trim() !== JSON.stringify(t2).trim()){
          return false;
        }        
      }
      return sameString;
      

    }
    componentDidUpdate(prevProps) {
      prodData = this.props.prodData
      searchTerm = this.props.searchTerm;
      oldSearchTerm = prevProps.searchTerm;
      numberofRows = this.props.numberofRows;
      if(searchTerm !== null){
        sameSearch = this.checkIfSameSearchString(oldSearchTerm, searchTerm);
      if(!sameSearch){
        let searchTerm = this.props.searchTerm;        
      if(Object.keys(searchTerm).length > 0){
        let seacrObject = JSON.stringify(searchTerm);
        this.setState({
            searchTerm:searchTerm,
            searchTermLoaded : 1,
            clickedSearch:1,
            data:[],
            numberofRows:numberofRows,
            onlyValid: searchTerm.onlyValid,
            currentPage: searchTerm.currentPage,
            loading:true                
        })
        //let url = `${API_URL}search?seacrhObj=${seacrObject}`;
       // console.log(`${API_URL}search?seacrhObj=${seacrObject}`)
        axios.post(`${API_URL}search`, {
          seacrhObj: seacrObject
        }).then(response => {
                if(response.status === 200 && response.data.data.length >0){
                    
                    let totalPage = (response.data.rowsFound[0].total_row)%numberofRows === 0 ? (response.data.rowsFound[0].total_row)/numberofRows : parseInt((response.data.rowsFound[0].total_row)/numberofRows)+1 ;
                    this.setState({
                        data: response.data.data,
                        totalRowsFound:response.data.rowsFound[0].total_row,
                        totalPage: totalPage,
                        loading:false                        
                    })
                }else if(response.status === 201){
                  this.setState({
                    formAlert:true,
                    loading:false,
                    emptyModal:!this.state.emptyModal,
                    modalResponse:response.data.EmptySearch
                   
                  })
                }else{
                    this.setState({
                        data: [],
                        loading: false,
                        totalRowsFound: response.data.rowsFound[0].total_row,
                    })
                }                     
            }).catch( (err) => {
              document.location.href="/";
              // this.setState({
              //   forceLogOut:true
              // })
            })
        }
      }
      
    
   
    }
    
        
    }
    
    render() {     
     
      let renderProductTable;
      let renderTableHeader;
      let renderFixedHeader;
      let showSpinningPill = true;
      let renderTableBody;
      let renderFixedTableBody;
      let renderPagination;
      let renderModalBody="";
      let modalTitle;
      let divOneSpHeight = "";

      const { orderBy, orderDirection} = this.state;
      if(orderDirection !==""){
        this.highlightSortedColumn(orderBy, orderDirection)
      }

      
      initialNumber  = (currentPage-1)*numberofRows+1;
      lastNumber = currentPage*numberofRows;
      if(this.state.totalRowsFound>0){
        renderPagination=(
          <div className="pagination_style">
            <div className="product_counter">{this.state.totalRowsFound}{(this.state.totalRowsFound>1?" records":" record")}  found</div>
            {/* <div className="pagination_container">
                <span className={(this.state.currentPage ===1 ) ? 'disabled_link': 'regular_link'}  onClick={this.goFirstPage} >{arrowDoubleLeft}</span> &nbsp; &nbsp;
                <span className={(this.state.currentPage ===1 ) ? 'disabled_link': 'regular_link'}  onClick={this.goPrevPage} >{arrowLeft}</span> &nbsp;
                    {this.state.currentPage} / {this.state.totalPage} &nbsp;
                <span className={(this.state.currentPage === this.state.totalPage ) ? 'disabled_link': 'regular_link'}  onClick={this.goNextPage} >{arrowRight}</span> &nbsp; &nbsp;
                <span className={(this.state.currentPage === this.state.totalPage ) ? 'disabled_link': 'regular_link'}  onClick={this.goLastPage} >{arrowDoubleRight}</span>
            </div> */}
            
          </div>
          
        )
      }
      
        //divOneSpHeight = '85vh'
      let mainWIndow = document.getElementById('dashboardWindow');
      if(mainWIndow){
        let containerHeight = mainWIndow.offsetHeight;
        let tabHeight = document.getElementById("walkthrough_salesval").offsetHeight;
        let footerHeight = document.getElementById("footer").offsetHeight;
        
        if(isFirefox ){        
          //let div2Height = (containerHeight+10)-(tabHeight+(footerHeight*2)+5)
          div1Height = (containerHeight)-(tabHeight+(footerHeight*2)+10)
          div2Height = div1Height+17;
          
        }else{
          div1Height = (containerHeight)-(tabHeight+(footerHeight*2))
          div2Height = div1Height+8; // chrome, safari, edge       
        }

      }
      
      if(this.state.totalRowsFound>0){
        
            renderTableBody = ( 
              <tbody  onMouseOut={this.mouseOut}>
                {this.state.data.map((row, index) => {
                  let price =  new Intl.NumberFormat('sv-SE',formatConfig).format(row.contract_price)
                  let contractPrice = row.hasAlteration ? <a href="#">{price}</a>:{price};
                  let lowestPriceNan = new Intl.NumberFormat('sv-SE',formatConfig).format(row.lowest_price_nan)
                  let lowestPricePord = new Intl.NumberFormat('sv-SE',formatConfig).format(row.lowest_price_prod)
                  let approxAup  = new Intl.NumberFormat('sv-SE',formatConfig).format(row.approx_aup)
                  let currentAip  = new Intl.NumberFormat('sv-SE',formatConfig).format(row.current_aip)
                  return (
                    
                    <tr key={index} id={"table2_"+index} className="clickable" onMouseOver={this.getRowId}>
                        <td id={index+'_form'} className="text-left" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.form}</td>                       
                        <td id={index+'_nan'} className="text-center" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.nan}</td>
                        <td id={index+'_approx_aup'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}> {row.approx_aup>0 ? approxAup+' SEK':''}</td>
                        <td id={index+'_current_aip'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}> {row.current_aip>0 ? currentAip+' SEK':''}</td>
                        <td id={index+'_contract_price'} className="text-right " onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>
                          <a href="#" className={row.hasAlteration? "highlightText":"regularText"}>{row.contract_price> 0 ? price+' SEK':''}</a>
                        </td>
                        <td id={index+'_discount'} className="discount text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.discount === "0%"? "":row.discount}</td>
                        <td id={index+'_lowest_price_nan'} className="text-right" onClick={row.lowest_price_nan>0 ? (e)=>this.renderFiguersAndCharts(e, "lowest_price_nan", row):row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>
                          <a href="#"  className={(row.lowest_price_nan !== '' ) ? "highlightText":"regularText"}>{row.lowest_price_nan>0 ? lowestPriceNan+' SEK':''}</a>
                        </td>
                        <td id={index+'_lowest_price_nan'} className="text-right" onClick={row.lowest_price_prod>0 ? (e)=>this.renderFiguersAndCharts(e, "lowest_price_prod", row):row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>
                          <a href="#"  className={(row.lowest_price_prod !== '' ) ? "highlightText":"regularText"}>{row.lowest_price_prod>0 ? lowestPricePord+' SEK':''}</a>
                        </td>
                        <td id={index+'_contract_from'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.contract_from}</td>
                        <td id={index+'_contract_to' } className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.contract_to}</td>
                        <td id={index+'_tender_serial'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.tender_serial}</td>
                        <td id={index+'_max_extension_to'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.max_extension_to}</td>

                    </tr>
                    
                    
                  );                  
                })}
              </tbody> 
            );
            renderFixedTableBody = (
              <tbody  onMouseOut={this.mouseOut}  style={{minWidth:'100%'}}>
                {this.state.data.map((row, index) => {
                  let price = row.contractPrice
                  let contractPrice = row.hasAlteration ? <a href="#">{price}</a>:{price};
                  var regionText = (row.subord === '') ? row.short_name : row.short_name+' (Coop.:'+row.subord+') ';
                  
                  return (
                    
                    <tr key={index} id={"table1_"+index} className="clickable" onMouseOver={this.getRowId}>
                        <td id={index+'_county_name'} className="text-left" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{regionText}</td>
                        <td id={index+'_atc_code'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.atc_code}</td>
                        <td id={index+'_substance'} className="text-left" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.substance}</td>
                        <td id={index+'_company'} className="text-left" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.company}</td>
                        <td id={index+'_name'} className="text-left" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.name}</td>
                        
                        <td id={index+'_strength'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.strength}</td>
                        <td id={index+'_size'} className="text-right" onClick={row.hasAlteration ?(e)=>this.renderFiguersAndCharts(e, "contract_price", row):(e)=>this.renderFiguersAndCharts(e, "show_nothing", row)}>{row.size}</td>
                        
                        
                    </tr>
                    
                  );                  
                })}
              </tbody>
            )
          }
        
        renderTableHeader = (
            <tr>
              {columns.map((suggestion, index) => {
                if(!fixedCol.includes(suggestion.field)){
                return (
                  <th  key={index} id={suggestion.field} //contract-price 
                    className={( notSortable.includes(suggestion.field )? 'not_clickable':'clickable')} 
                    onClick = {notSortable.includes(suggestion.field )? this.doNothing:this.sortOnFly}>
                       {suggestion.label} &nbsp;
                       {notSortable.includes(suggestion.field )? <span>&nbsp;</span>: <span id={suggestion.field+'_asc'} className="asc hidden">▲</span>}
                       {notSortable.includes(suggestion.field )? <span>&nbsp;</span>: <span id={suggestion.field+'_desc'} className="desc hidden">▼</span>}
                  </th>
                );
              }
              })}
            </tr>
          );
          
          renderFixedHeader = (
            <tr>
              {columns.map((suggestion, index )=> {
                if(fixedCol.includes(suggestion.field)){
                  return (
                    <th scope="col" key={index} id={suggestion.field} //contract-price 
                      className={( notSortable.includes(suggestion.field )? 'not_clickable':'clickable')} 
                      onClick = {notSortable.includes(suggestion.field )? this.doNothing:this.sortOnFly}>
                        {suggestion.label} &nbsp;
                        {notSortable.includes(suggestion.field )? <span>&nbsp;</span>: <span id={suggestion.field+'_asc'} className="asc hidden">▲</span>}
                        {notSortable.includes(suggestion.field )? <span>&nbsp;</span>: <span id={suggestion.field+'_desc'} className="desc hidden">▼</span>}
                    </th>

                  )

                }
              })}

            </tr>
          )
          
          if(this.state.data.length > 0 && this.state.clickedSearch === 1 && !this.state.nextOrPrevClicked){
            showSpinningPill = false;
            // onScroll={this.calulateWidth}
            renderProductTable = (
              <ScrollSync>
                <div className="parent"  >
                  <table id="main_table" className="table_row" >
                    <tbody>
                      <tr>                  
                        <td id="first_td_container">
                          <ScrollSyncPane group="vertical">  
                            
                              <table id="parent_div_1" style={{maxHeight:div1Height}} ref={this.contractWrapper}>
                                <thead id="table_header" >
                                  {renderFixedHeader}
                                </thead>                
                                  {renderFixedTableBody}
                              </table>
                            
                          </ScrollSyncPane>
                        </td>
                        <td>
                          <ScrollSyncPane group="vertical">
                            
                              <table id="parent_div_2"  style={{ maxWidth: this.state.secondWidth, maxHeight:div2Height}} >
                                <thead >
                                  {renderTableHeader}
                                </thead>
                                  {renderTableBody}
                              </table>
                            
                          </ScrollSyncPane>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ScrollSync>
            )
         }else if(  this.state.clickedSearch === 1 || ( this.state.clickedSearch === 1 && this.state.nextOrPrevClicked)){
            showSpinningPill = true;
            if(this.state.loading){
                renderProductTable = (
                    <div className={(showSpinningPill) ? 'spinning_pill': 'spinning_pill_hidden'}>
                                
                                <img src={spinning_pill} alt="spinning_pill" />
                    </div>              
                )
            }else{
                renderProductTable = (
                    <div className="no_data_found">No records found</div>            
                )
            }
            
        }else if(this.state.totalRowsFound === 0 && this.state.clickedSearch === 0 ){
            showSpinningPill = false;
            renderProductTable = (
                <div className="no_data_found">Search for products</div>            
            )
        }
        
        
        if(this.state.modalData ==='show_nothing'){
          renderModalBody = (
            <div id="modal_body">
              <table className="description-table" style={{width:"100%"}}>
                <tbody>
                  <tr className="description">
                    <td><b>No contract alterations available</b></td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          )

        }

        if(this.state.modalData === "contract_price"){
          renderModalBody =  (this.state.modalApiResponse.length>0)
          ? (
            
            <div id="modal_body">
              <table className="description-table">
                <tbody>
                  <tr className="description">
                    <td><b>NAN</b></td>
                    <td>{this.state.objForModal.nan}</td>
                  </tr>
                  <tr className="description">
                    <td><b>Region</b></td>
                    <td>{this.state.objForModal.short_name}</td>
                  </tr>
                  <tr className="description">
                    <td><b>Contract from</b></td>
                    <td>{this.state.objForModal.contract_from}</td>
                  </tr>
                </tbody>
              </table>
              <div id="modal-table-wrapper">
              <table>
                <thead>
                  <tr><th className="text-left padding-left-20">Date</th><th className="text-left padding-left-20">Type</th><th className="text-left padding-left-20">Old value</th><th className="text-left padding-left-20">New value</th></tr>
                </thead>
                <tbody>
                  {
                    this.state.modalApiResponse.map((row, index) => {                  
                      return (
                        <tr key={index} id={index}>
                          {/* <td className="text-center">{row.time.split(" ",1)}</td><td className="capitalize text-center">{(row.column_altered).split("_").join(" ")}</td><td className="text-center">{row.old_value}</td><td className="text-center">{row.new_value}</td> */}
                          <td className=" padding-left-10 text-left">{row.time !=="" ? row.time.includes(" ")?row.time.split(" ",1):row.time: ""}</td><td className="padding-left-10 capitalize text-left">{(row.column_altered!=="") ? row.column_altered.includes("_")?(row.column_altered).split("_").join(" "):row.column_altered:""}</td><td className="padding-left-10 text-left">{row.old_value}</td><td className="padding-left-10 text-left">{row.new_value}</td>
                        </tr>
                      )
                    })}
                </tbody>
                
              </table>
              </div>

            </div>
            
          ):(
            <div>loading...</div>
          )
        }
        
        if(this.state.modalData === "lowest_price_nan" || this.state.modalData === "lowest_price_prod" ){

          let dataToDraw = [];
          valueToDraw = [];
          //valueToDraw.push(["Region", "Contract Prices(SEK)", { role: "style" }, { role: 'annotation' }])
          valueToDraw.push(["Region", "Contract Price (SEK)"])
          const options = {
            vAxis: { title: "Price (SEK)", viewWindow: { min: 0 } },
            legend: "none"
          };
          if(this.state.modalApiResponse.length>0){
            
           dataToDraw=  this.state.modalApiResponse.map(obj =>{
            //  let contractPrice = Number(obj.contract_price).toFixed(2);
            //  let cpFromatted = contractPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            //  let nFormatted =  cpFromatted.replace('.', ',');
             
            //  console.log(parseFloat(nFormatted))
              //var rObj = [obj.county_name, Number(obj.contract_price), '#1a427b', obj.contract_price+' SEK'];
              var rObj = [obj.county_name, Number(obj.contract_price)];
              valueToDraw.push(rObj)
              return rObj;
              
            })
            
          }
          renderModalBody =  (this.state.modalApiResponse.length>0)
          ? (
            <div id="modal_body">
              <table className="description_contract_price">
                <tbody>
                  <tr className="modal_substance_header">
                    <td colSpan="3"><b>{this.state.objForModal.substance}</b></td>
                  </tr>
                  <tr>
                    <td><b>Strength: {this.state.objForModal.strength}</b></td><td><b> Size: {this.state.objForModal.size}</b></td><td><b>Form: {this.state.objForModal.form}</b></td>                    
                  </tr>
                </tbody>
              </table>
              <div>
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={valueToDraw}
                  options={options}
                  formatters={[
                    {
                      type: 'NumberFormat',
                      column: 1,
                      options: {
                        fractionDigits: 2,
                        groupingSymbol: ' ',
                        decimalSymbol:','
                        
                      },
                    },
                  ]}
                />               
              </div>
            </div>
            
            
          ):(
            <div>loading...</div>
          )
         
        }
        if(this.state.modalData === "contract_price" || this.state.modalData === "show_nothing"){
          modalTitle = (
            <span>Contract Alterations</span>
          )
        }
        if(this.state.modalData === "lowest_price_nan" || this.state.modalData === "lowest_price_prod"){
          modalTitle = (
            <span>Contract Prices</span>
          )
        }
        let modalBodyFormAlert = "";
        if(this.state.formAlert){
          modalBodyFormAlert = (
            
            <Modal style=  {{maxWidth: '700px', width: '700px'}}  isOpen={this.state.emptyModal} toggle={this.toogleEmptyModal}>
                <ModalHeader  toggle={this.toogleEmptyModal} charCode="x" className="modal_title">Empty search form!</ModalHeader>
                <div className="form_alert"><p> {this.state.modalResponse}</p></div>
                <ModalFooter> 
                  <button onClick={this.toogleEmptyModal} type="button" className="form-button">Ok</button>                 
                </ModalFooter>
              </Modal>
          )
        }
      
        
        return ( 
            <div onMouseEnter={this.handleTable} id="prod_contract_div" >
                <div className="float-container">
                  {renderPagination}
                  {renderProductTable}
                </div>
                <div style={{width:'100vw'}}>
                  {modalBodyFormAlert} 
                </div>
                
                  <Modal style={this.state.modalData === "contract_price"? {maxWidth: '40vw', width: '40%'}:this.state.modalData === "show_nothing"?{maxWidth: '30vw', width: '30%'}: {maxWidth: '95vw', width: '80%'}}  className="main_modal" isOpen={this.state.modal} toggle={this.renderFiguersAndCharts}  >
                    <ModalHeader toggle={this.renderFiguersAndCharts} charCode="x" className="modal_title">{modalTitle}</ModalHeader>
                    <ModalBody className="modalBody">
                      {renderModalBody}
                    </ModalBody>
                    <ModalFooter> 
                      <button onClick={this.renderFiguersAndCharts} type="button" className="form-button">Close</button>                 
                    </ModalFooter>
                  </Modal>
                
            </div>
            
        );
    }
}